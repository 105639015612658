import React from 'react';

const TicketTopBar = () => {
    return (
        <div className="user-profile"><h5 className="my-order-title text-center">My Support Tickets</h5>
            <div className="support-ticket-category text-center">
                <button className="support-ticket-category-btn">Open</button>
                <button className="support-ticket-category-btn">Answered</button>
                <button className="support-ticket-category-btn">Customer Reply</button>
                <button className="support-ticket-category-btn">Close</button>
            </div>
        </div>
    );
};

export default TicketTopBar;
