import React from 'react';
import UserProfileTopBar from "../../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../../UserDashboard/Include/UserProfileSideBar";
import $ from "jquery";
import {Helmet} from "react-helmet";


const ChangePassword = () => {
    const handlePasswordShowEyeOne = (id) => {
        let element = $('#'+id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    };
    const handlePasswordShowEyeTwo = (id) => {
        let element = $('#'+id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }
    return (
        <>
            <Helmet>
                <title>Change Password | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <div className={'password-section-main-new'}>
                                <div className="change-pass-title text-center">
                                    <h6>Change Password</h6>
                                </div>
                                <div className="row row-margin-top justify-content-center align-items-center">
                                    <div className="col-md-6 custom-margin-top ">
                                        <div className="password-section-inner">
                                            <form>
                                                <div className="pass-group mb-3 position-relative">
                                                    <label>New Password:</label>
                                                    <input type="password" id={'login_password_one'} className="form-control pass-control" placeholder="New Password"/>
                                                    <div className="eye-icon-pass-change" onClick={()=>handlePasswordShowEyeOne('login_password_one')}>
                                                        <i className={'fas fa-eye'}/>
                                                    </div>
                                                </div>
                                                <div className="pass-group mb-3 position-relative">
                                                    <label>Confirm Password:</label>
                                                    <input type="password" id={'login_password_two'} className="form-control pass-control" placeholder="Confirm Password"/>
                                                    <div className="eye-icon-pass-change" onClick={()=>handlePasswordShowEyeTwo('login_password_two')}>
                                                        <i className={'fas fa-eye'}/>
                                                    </div>
                                                </div>
                                                <div className="pass-change-button text-center">
                                                    <button className={'change-password-btn'} type={'button'}>Change Password</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ChangePassword;
