import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import TableTransactionList from "./Include/TableTransactionList";
import {Link} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";

const TransactionList = () => {

    return (

        <div>
            <Helmet>
                <title>Transaction List | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <div className="all-transaction">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 account-balance new-balance bg-none">
                                        <div className="acc-add-fund">
                                            <p className={'balance'}>Balance: {localStorage.balance != undefined ? localStorage.balance : 0}  {localStorage.currency}</p>
                                            <div className="withdraw-and-add-fund">
                                                <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'add-fund'}>Add Fund +</Link></button>
                                                <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'/payment-withdrew'}>Withdraw</Link> </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="transaction-table-list-new">
                                <TableTransactionList/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TransactionList;

