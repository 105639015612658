import React from 'react';

const ViewTicketTopBar = () => {
    return (
        <div className="view-tickets-top-bar">
            <div className="row text-center align-items-center">
                <div className="col-md-6">
                    <div className="ticket-id-and-subject">
                        <p><span className={'pe-2'}>Ticket ID:</span> 987546</p>
                        <p>For domain pytasker.com , we have again placed</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="ticket-id-and-subject">
                        <p><span className={'pe-2'}>Category:</span> General</p>
                        <p><span className={'pe-2'}>Last Updated:</span>7 hours ago</p>
                        <div className="close-btn-flex">
                            <p><span className={'pe-2'}>Status:</span> Answered</p>
                            <button className={'ticket-close-btn'} type={"button"}>Close</button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewTicketTopBar;
