import React from 'react';
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import ViewTicketTopBar from "./Include/ViewTicketTopBar";
import ViewTicketChat from "./Include/ViewTicketChat";
import {Helmet} from "react-helmet";

const ViewTickets = () => {
    return (
        <div>
            <Helmet>
                <title>Ticket Details | Teer 125</title>
            </Helmet>
            <div>
                <UserProfileTopBar/>
                <div className="container">
                    <div className={'user-profile-main'}>
                        <div className="row">
                            <div className="col-md-3 profile-sidebar-common-class">
                                <UserProfileSideBar/>
                            </div>
                            <div className="col-md-9">
                               <ViewTicketTopBar/>
                                <ViewTicketChat/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewTickets;
