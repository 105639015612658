import React from 'react';
import {Link} from "react-router-dom";
import image1 from '../../Assets/images/teer-img/teer2.png'


const InvestmentNineHundred = () => {
    return (
        <div className={'container'}>
            <div className="row">
                <div className="col-md-12">
                    <Link to={'/nine-hundred'}>
                        <div className={'twenty-five-section-main'}>
                            <h2 className={'text-center teer-result-heading'}>Talent Teer Three Numbers Results</h2>
                            <div className="row align-items-center text-center ">
                                <div className="col-sm-4 col-4 text-center">
                                    <p className={'ninehundred-color'} >Sunday Teer</p>
                                </div>
                                <div className="col-sm-4 col-4 text-center" >
                                    <img className={'teer-img-two'} src={image1} alt=""/>
                                </div>
                                <div className="col-sm-4 col-4 text-center">
                                    <h6 className={'ninehundred-color'}>Amount</h6>
                                    <p className={'ninehundred-color'}>10 X 8000</p>
                                </div>
                            </div>
                            <p className={'text-center mt-3 ninehundred-color'}>10:PM</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default InvestmentNineHundred;
