import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import image1 from '../../Assets/images/teer-img/teer-logo.png'
import image2 from '../../Assets/images/teer-img/teeer1.png'
import $ from 'jquery'
import axios from "axios";
//import 'react-wheel-of-prizes/dist/index.css'
// import '../../../node_modules/react-wheel-of-prizes/dist/index.css'

const InvestmentTwentyFive = () => {
    const [url, setUrl] = useState('');

    useEffect(() => {
        clearIntravelData()
        setIntravelRunning(false)
        setUrl(window.location.pathname)
        getWheelData()
        setInterval(()=>{
            getWheelData()
        }, 10000)





    }, [])

    const segments = [
        '25',
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
    ]

    const [wheelData, setWheelData] = useState([])
    const getWheelData = () => {
        if (localStorage.user_token != undefined){
            axios.get('/api/get-wheel-information').then(res=>{
                setWheelData(res.data)
            })
        }
    }

    useEffect(()=>{
        startRotate()
    },[wheelData])

    const rotateImage = (deg) => {
        $('.spinner').css('transform', `rotate(${deg}deg)`)
    }

    const [intravelData, setIntravelData] = useState()
    const [isIntravelRunning , setIntravelRunning] = useState(false)
    const startRotate = () => {
        console.log('inside function')
        console.log(wheelData.run)
        let degree = wheelData.result != undefined ? wheelData.result : 0
        if (wheelData.run==1 ){
            console.log('works')
            console.log('degree')
            setIntravelRunning(true)
            if (!isIntravelRunning){
                setIntravelData(setInterval(() => {
                        degree = (degree + 14.4) % 360
                        rotateImage(degree)
                    }, 150)
                )
            }
        }else{
            clearIntravelData()
            setIntravelRunning(false)
        }
    }

    const clearIntravelData = () => {
        clearInterval(intravelData)
        let result = wheelData.result
        let degree = result * 14.4
        $('.spinner').css('transform', `rotate(-${degree}deg)`)

        if (result != 25) {
            if (result % 2 == 0) {
                $('.result-display').css({'background': '#69bd45', 'color': 'white'})
                $('#teer_trangle').css({'color': '#69bd45'})
            } else {
                $('.result-display').css({'background': '#f3ec19', 'color': 'black'})
                $('#teer_trangle').css({'color': '#f3ec19'})
            }
        } else {
            $('.result-display').css({'background': '#ED1F24', 'color': 'white'})
            $('#teer_trangle').css({'color': '#ED1F24'})
        }
    }


    useEffect(() => {
        return () => {
            clearIntravelData()
            setIntravelRunning(true)
        };
    }, []);


    return (
        <>

            <Link to={'/twenty-five'}>
                <div className="twenty-five-section-main">
                    <h1 className={'text-center teer-result-heading'}>Shillong Teer 25 Number Live Game Online Results</h1>
                    {url == '/user-profile' ?
                        <div className="teer-point text-center text-white">
                            <p>WINNER!</p>
                            <h4>13</h4>
                        </div> : null
                    }
                    {url == '/twenty-five' ?
                        <>
                            <div className="result-display-area text-center">
                                <div className="result-display">
                                    <h6>{wheelData.round}</h6>
                                    <h6 className={'mt-2'}>{wheelData.time}</h6>
                                </div>

                            </div>
                            <div className="teer-triangle" id={'teer_trangle'}>
                                <i className="fas fa-play"/>
                            </div>
                        </>
                        : null
                    }
                    <div className="teer-board text-center">
                        {url == '/' ?
                            <>
                                <img className={'teer-hand-image'} src={image1} alt=" why "/>
                                <img className={'invest-teer-image'} src={image2} alt=" why "/>
                            </>
                            :
                            <div className="spinner">
                                <div className="numbers">
                                    {segments.map((number, index) => (
                                        <span key={index} style={{transform: `translate(-50%, -50px) rotate(${number * 14.4}deg)`}} data-type={number * 14.4}>{number}</span>
                                    ))}
                                </div>
                                <img className={'invest-teer-image'} src={image2} alt=""/>
                            </div>

                        }

                    </div>
                    {/*<button className={'d-none'} onClick={clearIntravelData}>Clear</button>*/}
                    <div className="teer-result-button text-center">
                        <button className={'teer-button-inner'} type={'button'}>Green</button>
                        <button className={'teer-button-inner button-red'} type={'button'}>Red</button>
                        <button className={'teer-button-inner button-yellow'} type={'button'}>Yellow</button>
                    </div>
                </div>
            </Link>


        </>
    );
};

export default InvestmentTwentyFive;
