import React, {useState} from 'react';
import {Link} from "react-router-dom";
import image from '../../Assets/images/logo/1.png'

const Footer = () => {
    const [information] = useState([
        {title: 'About', url: '/'},
        {title: 'Term Of Service', url: '/terms-and-condition'},
        {title: 'Privacy Policy', url: '/privacy-policy'},
        {title: 'Refund Policy', url: '/'},
    ])
    const [socialIcon]=useState([
        {icon: 'fab fa-facebook', url : 'https://facebook.com'},
        {icon: 'fab fa-whatsapp', url : 'https://wa.me/+916009877616'},
        {icon: 'fab fa-whatsapp', url : 'https://wa.me/+8801703945133'},
        {icon: 'fab fa-twitter', url : 'https://twitter.com'},
        {icon: 'fab fa-instagram', url : 'https://instagram.com'},
        {icon: 'fab fa-google', url : 'https://google.com'},
    ])
    return (
        <div className={'footer-section-main'}>
            <div className="container">
                <div className="row text-center">
                <div className="col-lg-3 col-md-6">
                        <div className="footer-content-inner">
                            <Link className="custom-nav-bar-brand-footer" to="/">Teer<span
                                className={'custom-color'}>125</span></Link>
                            <a href={'mailto:teer125@gmail.com'}
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fas fa-envelope"/></span>
                                    teer125@gmail.com
                                </a>
                            
                                <br/><a href={'https://wa.me/+916009877616'} target="_blank"
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fab fa-whatsapp"/></span>
                                    +916009877616
                                </a><br/>
                                <a href={'https://wa.me/+8801703945133'} target="_blank"
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fab fa-whatsapp"/></span>
                                    +8801703945133
                                </a>
                            <div className="top-bar-section-inner text-center mt-2">
                                {socialIcon.map((item,index)=>(
                                    <a class="top-bar-social-icon-color" target="_blank" href={item.url}><i class={item.icon}></i></a>
                                    // <Link className={'top-bar-social-icon-color'} to={item.url} target={'_blank'} key={index}>
                                    //     <i className={item.icon}/>
                                    // </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-content-inner">
                            <h6 className={'footer-content-heading'}>Information</h6>
                            {information.map((item, index) => (
                                <Link to={item.url} className={'information-inner'} key={index}>{item.title}</Link>
                            ))}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-content-inner">
                            <h6 className={'footer-content-heading'}>Notification</h6>
                            <div className="input-group mt-3">
                                <input type="text" className="form-control email-control" placeholder="Email Us"/>
                                <span className="input-group-text">Subscribe</span>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-content-inner">
                            <h6 className={'footer-content-heading'}>APP Download</h6>
                            <a href={'teer125.apk'}><img class={'app-download-img'} src={image} alt="Teer125APP Download"/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
