import React, {useEffect, useState} from 'react';
import TopBar from "../../Include/TopBar";
import NavBar from "../../Include/NavBar";
import NewsTicker from "../../Include/NewsTicker";
import Footer from "../../Include/Footer";
import CopyRight from "../../Include/CopyRight";
import loader from "../../../Assets/images/spinner/bars.svg";
import ripple from "../../../Assets/images/spinner/ripple.svg";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";


const PreviousHistory = () => {
    const [winnerModalShow, setWinnerModalShow] = useState(false)
    const winnerModal = () => {
        winnerModalShow ? setWinnerModalShow(false) : setWinnerModalShow(true)
    }
const [prehistory, setPreHistory]=useState([])

const [isLoading, setIsLoading] = useState(false)
const [is25Loading, setIs25Loading] = useState(false)
const [is100Loading, setIs100Loading] = useState(false)
const [is900Loading, setIs900Loading] = useState(false)
const [result25_data, setResult25_data] = useState(false)
const [result100_data, setResult100_data] = useState(false)
const [result900_data, setResult900_data] = useState(false)
const [resultDateData, setResultDateData] = useState(false)

const [Result25, setResult25] = useState([])
const [Result100, setResult100] = useState([])
const [Result900, setResult900] = useState([])

    const showHistory = () => {
        setIsLoading(true)
        setResultDateData(true)
        axios.get('/api/previous-dates').then(res => {
            setPreHistory(res.data)
            if(res.data == 0){
                setResultDateData(false)
            }
            setIsLoading(false)
        })        
    }

    const PreviousDateInput = (e, date) => {
        date = e.target.value
        setIsLoading(true)
        setResultDateData(true)
        axios.get('/api/previous-dates/' + date).then(res => {
            setPreHistory(res.data)
            if(res.data == 0){
                setResultDateData(false)
            }
            setIsLoading(false)
        })
      }

    useEffect(() => {
      showHistory()
    }, []);

    const previousDate = (e, date) => {
        // console.log(date)
        setIs25Loading(true)
        setResult25_data(true)
        axios.get('/api/previous-result25/' + date).then(res => {
            // console.log(res)
            setResult25(res.data.data)
            if(res.data.data == 0){
                setResult25_data(false)
            }
            setIs25Loading(false)
        })

        setIs100Loading(true)
        setResult100_data(true)
        axios.get('/api/previous-result100/' + date).then(res => {
            setResult100(res.data.data.area)
            if(res.data.data.area == 0){
                setResult100_data(false)
            }
            setIs100Loading(false)
        })

        setIs900Loading(true)
        setResult900_data(true)
        axios.get('/api/previous-result900/' + date).then(res => {
            console.log(res.data.data)
            setResult900(res.data.data)
            if(res.data.data == 0){
                setResult900_data(false)
            }
            setIs900Loading(false)
        })        
    }

    return (
        <div>
            <Helmet>
                <title>Previous History | Teer 125</title>
            </Helmet>
            <TopBar/>
            <NavBar/>
            <NewsTicker/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="previous-result-main">
                            <div className="previous-section-inner">
                                <div className="previous-section-top">
                                    <h1 className={'previous-result-title'}>Previous Result History</h1>
                                    <input className={'history-control'} onChange={(e)=>PreviousDateInput(e, 'date')} type={'date'}/>
                                </div>
                                {isLoading ?
                                 <div className={'table-data-loader'}>
                                <img src={ripple} alt={'Loader'}/>
                                </div> : 
                                <div className="previous-accordion">
                                    {!resultDateData ?
                                        <div className={'result_not_found'}>
                                                Results Date Not Found
                                            </div> : null
                                        }
                                    <Accordion allowZeroExpanded>
                                        {prehistory.map((item,index)=>(
                                            <AccordionItem key={index} >
                                                <AccordionItemHeading type={'button'} onClick={(e)=>previousDate(e,item)}>
                                                    <AccordionItemButton>
                                                        {item}
                                                    </AccordionItemButton>
                                                </AccordionItemHeading>
                                                <AccordionItemPanel>
                                                {is25Loading ?
                                                            <div className={'table-data-loader'}>
                                                                    <img src={loader} alt={'Loader'}/>
                                                                </div> : 
                                                            
                                                    <div className="history-box">
                                                        <div className="history-box-inner">
                                                            <h6 className={'text-center'}>Shillong Teer 25 Number Results</h6>
                                                            
                                                            {!result25_data ?
                                                                <div className={'result_not_found'}>
                                                                        Results Not Found
                                                                    </div> : null
                                                             }
                                                        </div>                                                        
                                                        {Result25.map((result25,index)=>(
                                                        <div  className="result-history">
                                                            <div className="row justify-content-center align-items-center">
                                                                <div className="col-6 col-md-8">
                                                                    <div className="row">
                                                                        <div className="col-md-6 text-md-start text-center">
                                                                            <p>{result25.round}</p>
                                                                        </div>
                                                                        <div className="col-md-6 text-center">
                                                                            <p>{result25.time}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-4">
                                                                    <div className="winner-flex">
                                                                        <p className={'winner-cup'}><span
                                                                            className={'cup-padding'}><i
                                                                            className="fas fa-trophy"/></span>{result25.winner_number}</p>
                                                                        {/* <p className={'winner-padding winner-bg'}
                                                                           onClick={winnerModal}>Winner</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))}
                                                    </div>
                                                    }
                                                    {is100Loading ?
                                                    <div className={'table-data-loader'}>
                                                        <img src={loader} alt={'Loader'}/>
                                                    </div> : 
                                                    <div className="history-box">
                                                        <div className="history-box-inner">
                                                            <h6 className={'text-center'}>Shillong Teer 100 Number
                                                                Results</h6>
                                                                {!result100_data ?
                                                                <div className={'result_not_found'}>
                                                                        Results Not Found
                                                                    </div> : null
                                                                }
                                                        </div>
                                                        {Result100.map((result100,index)=>(
                                                        <div className="result-history-hundred">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <h6 className={'teer-result-name'}>{result100.name}</h6>
                                                                    {result100.frsr.map((frsr,index)=>                                                                
                                                                    <div className="teer-result-bg">                                                                        
                                                                        <div className="row justify-content-center align-items-center">
                                                                            <div className="col-6 col-md-8">
                                                                                <div className="row">
                                                                                    <div className="col-md-6 text-md-start text-center">
                                                                                       <p>{frsr.name}</p>                                                                                   
                                                                                    </div>
                                                                                    <div className="col-md-6 text-center">
                                                                                        <p>{frsr.time}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-md-4">
                                                                                <div className="winner-flex">
                                                                                    <p className={'winner-cup'}><span
                                                                                        className={'cup-padding'}><i
                                                                                        className="fas fa-trophy"/></span>{frsr.result}</p>
                                                                                    {/* <p className={'winner-padding winner-bg'}
                                                                                       onClick={winnerModal}>Winner</p> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>                                                                         
                                                                    </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        ))}
                                                    </div>
                                                    }
                                                    {is900Loading ?
                                                        <div className={'table-data-loader'}>
                                                            <img src={loader} alt={'Loader'}/>
                                                        </div> : 
                                                        !result900_data ? null :
                                                    <div className="history-box">
                                                        <div className="history-box-inner">
                                                            <h6 className={'text-center'}>Shillong Teer 900 Number  Results</h6>                          
                                                        </div>
                                                        {Result900.map((result900,index)=>(
                                                        <div className="result-history">
                                                            <div className="row justify-content-center align-items-center">
                                                                <div className="col-6 col-md-8">
                                                                    <div className="row">
                                                                        <div className="col-md-6 text-md-start text-center">
                                                                            <p>{result900.round}</p>
                                                                        </div>
                                                                        <div className="col-md-6 text-center">
                                                                            <p>{result900.time}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6 col-md-4">
                                                                    <div className="winner-flex">
                                                                        <p className={'winner-cup'}><span
                                                                            className={'cup-padding'}><i
                                                                            className="fas fa-trophy"/></span>{result900.winner_number}</p>
                                                                        {/* <p className={'winner-padding winner-bg'}
                                                                           onClick={winnerModal}>Winner</p> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        ))}
                                                    </div>
                                                    }
                                                </AccordionItemPanel>
                                            </AccordionItem>
                                        ))}


                                    </Accordion>
                                    {/* <div className="all-transaction-page-info">
                                        <div className="data-tables-info">
                                            <p>Showing 1 to 3 of 3 entries</p>
                                        </div>
                                        <div className="data-tables-paginate">
                                            <nav>
                                                <ul className="pagination">
                                                    <li className="page-item disabled">
                                                        <Link className="page-link" to={'/'}>Previous</Link>
                                                    </li>
                                                    <li className="page-item active-custom">
                                                        <Link className="page-link" to={'/'}>1</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>2</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>3</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>Next</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div> */}
                                </div>}
                            </div>

                            {/*Winner Modal start*/}
                            <div className="winner-modal">
                                <Modal
                                    size="sm"
                                    centered
                                    show={winnerModalShow}
                                    onHide={winnerModal}
                                    className={'winner-modal-content'}
                                >
                                    <div className={'modal-top-bar'}>
                                        <div className="winner-result-date">
                                            <p className={'result-header-title-modal text-center'}>10-01-2021</p>
                                            <span onClick={winnerModal}><i className="fas fa-times"/></span>
                                        </div>
                                        <div className="result-round-and-time-flex">
                                            <p className={'result-header-title'}>1st Round</p>
                                            <p className={'result-header-title'}>10:00 PM</p>
                                        </div>
                                    </div>
                                    <Modal.Body>
                                        <div className="winner-details">
                                         <p className={'text-center pb-2'}>Winner List</p>
                                            <div className="winner-list-table">
                                                <table className="table custom-winner-table">
                                                    <thead className={'winner-table-head'}>
                                                    <tr className={'winner-table-head-row'}>
                                                        <th>User ID</th>
                                                        <th>Profit</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr className={'winner-table-body-row'}>
                                                        <td>TEER191</td>
                                                        <td>BDT 5000</td>
                                                    </tr>

                                                    <tr className={'winner-table-body-row'}>
                                                        <td>TEER191</td>
                                                        <td>BDT 5000</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            {/*Winner Modal End*/}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <CopyRight/>
        </div>
    );
};

export default PreviousHistory;
