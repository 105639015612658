import React from 'react';
import image1 from '../../../../Assets/images/clint/3.jpg'
import image2 from '../../../../Assets/images/clint/2.jpg'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const ViewTicketChat = () => {
    return (
        <>
        <div className="reply-section">
            <Accordion allowZeroExpanded={true}>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton reply>
                                <p className={'reply-text'}><span className={'reply-icon'}><i className="fas fa-pen"/></span>Reply</p>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <div className="wring-msg-section">
                            <div className="App">
                                <h5 className={'ck-editor-name'}>Message</h5>
                                <CKEditor
                                    editor={ ClassicEditor }
                                    data="<p></p>"
                                    onReady={ editor => {
                                        // You can store the "editor" and use when it is needed.
                                        console.log( 'Editor is ready to use!', editor );
                                    } }
                                    onChange={ ( event, editor ) => {
                                        const data = editor.getData();
                                        console.log( { event, editor, data } );
                                    } }
                                    onBlur={ ( event, editor ) => {
                                        console.log( 'Blur.', editor );
                                    } }
                                    onFocus={ ( event, editor ) => {
                                        console.log( 'Focus.', editor );
                                    } }
                                />
                            </div>
                            <div className="submit-and-cancel-btn">
                                <div className="button-section">
                                    <button className={'btn-submit-cancel'}>Submit</button>
                                    <button className={'btn-submit-cancel cancel-btn'}>Cancel</button>
                                </div>
                                <div className="">
                                    <label className="attach-file-upload-icon-new">
                                        <i className="fas fa-paperclip"/>
                                        <input className="" type="file" id="file_upload" style={{display:'none'}}/>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        </div>
        <div className={'message-system'}>
            <div className="msg-show-filed">
                <div className="message-system-inner-clint">
                    <img className={'chating-img'} src={image1} alt=""/>
                    <p className={'clint-name'}>Imran</p>
                    <p className={'clint-status'}>Clint</p>
                </div>
                <div className="show-msg-inner">
                    <p>The following example contains a textarea:</p>
                </div>
            </div>
            <div className="msg-show-filed">
                <div className="message-system-inner-admin">
                    <img className={'chating-img'} src={image2} alt=""/>
                    <p className={'admin-name'}>Naimul Hasan</p>
                    <p className={'clint-status admin-status'}>Admin</p>
                </div>
                <div className="show-msg-inner">
                    <p>The following example contains a textarea:</p>
                </div>
            </div>
        </div>
        </>
    );
};

export default ViewTicketChat;
