import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import $ from 'jquery';

const UserProfileSideBar = () => {
    const subMenuShowHandler = (id) => {
        $('#' + id).siblings('.nav-main-submenu').slideToggle()
        let element_id = id.split('_')
        let length = Object.keys(menus).length
        for (let i = 0; i <= length; i++) {
            if (i != element_id[1]) {
                $('#mainMenu_' + i).siblings('.nav-main-submenu').slideUp()
            }
        }
    }
    const [menus] = useState([
        {
            name: 'Home',
            path: '/',
            icon: 'fa-home',
        },
        {
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'fa-users',
        },
        {
            name: 'Financial Report',
            path: '/transaction',
            icon: 'fa-chart-bar',
        },
        {
            name: 'Investment (25)',
            path: '/twenty-five',
            icon: 'fa-bars',
        },
        {
            name: 'Investment (100)',
            path: '/hundred',
            icon: 'fa-bars',
        },
        {
            name: 'Investment (900)',
            path: '/nine-hundred',
            icon: 'fa-bars',
        },
        {
            name: 'Previous Result',
            path: '/previous-result',
            icon: 'fa-bars',
        },
        {
            name: 'My Account',
            path: '/my-account',
            icon: 'fa-users',
        },
        // {
        //     name: 'Support Ticket',
        //     path: '/user-profile',
        //     icon: 'fa-hands-helping',
        //     subMenu: [
        //         {
        //             name: 'Open Ticket',
        //             path: '/open-ticket',
        //             icon: 'fa-bars',
        //         },
        //         {
        //             name: 'My Ticket',
        //             path: '/my-ticket',
        //             icon: 'fa-bars',
        //         },
        //         {
        //             name: 'View Ticket',
        //             path: '/view-ticket',
        //             icon: 'fa-bars',
        //         },
        //     ]
        // },

    ])
    const [searchTerm, setSearchTerm] = useState("")

    useEffect(() => {

        let window_width = $(window).width()
        if (window_width > 767){
            let width =  $('.profile-sidebar-common-class').innerWidth();
            width = width * .955
            $('#navbar_main_section').css('width', `${width}px`)
        }else{
            $('#navbar_main_section').css('width', '100%')
        }


    }, []);


    return (
        <div className={'user-profile-side-bar-main'}>
            <div className="navbar-section-main" id='navbar_main_section'>
                <div className="navbar-search-section">
                    <form>
                        <input type={'text'} className={'input-search-box text-center'} placeholder="Search Menu"
                               onChange={event => {
                                   setSearchTerm(event.target.value)
                               }}/>
                        <i className={'fas fa-search top-search-icon-nav'}/>
                    </form>
                </div>

                <div className="nav-menu-section">
                    {menus.filter((menu) => {
                        if (searchTerm == "") {
                            return menu
                        } else if (menu.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                            return menu
                        }
                    }).map((menu, index) => (
                        <div className="nav-main-menu" key={index}>
                            <div className="nav-menu-flex" id={`mainMenu_${index}`}
                                 onClick={() => subMenuShowHandler(`mainMenu_${index}`)}>
                                <div className="nav-menu-item-inner">
                                    <Link to={menu.path} onClick={menu.subMenu != undefined && Object.keys(menu.subMenu).length > 0 ? (event) => event.preventDefault() : null}
                                          className={'dashboard-item'}>
                                        <div className="custom-flex-menu">
                                            <i className={`fas ${menu.icon} custom-left-icon`}/>
                                            {menu.name}
                                        </div>
                                        {menu.subMenu != undefined ?  <i className="fas fa-chevron-right custom-right-icon"/> : null}

                                    </Link>
                                </div>
                            </div>
                            {menu.subMenu != undefined ?
                                <div className="nav-main-submenu" style={{display: 'none'}} id={'sub_menu'}>
                                    <div className="submenu-flex">
                                        {menu.subMenu.map((submenu, subIndex) => (
                                            <div className="submenu" key={subIndex}>
                                                <i className={'fas fa-arrow-right'}/>
                                                <Link to={submenu.path}
                                                      className={'submenu-title'}>{submenu.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div> : null
                            }
                        </div>
                    ))}
                     <div className="nav-main-menu">
                        <div className="nav-menu-flex">
                            <a href={'https://wa.me/+916009877616'} target="_blank"
                               className={'top-bar-font-color d-block d-md-none'}>
                                <span className={'top-bar-mail-icon mobile-whats-app-icon'}><i className="fab fa-whatsapp"/></span>
                                {localStorage.user_token != undefined ?
                                    <span>+916009877616</span> : null
                                }
                            </a>
                        </div>

                    </div>
                    <div className="nav-main-menu">
                        <div className="nav-menu-flex">
                            <a href={'https://wa.me/+8801703945133'} target="_blank"
                               className={'top-bar-font-color d-block d-md-none'}>
                                <span className={'top-bar-mail-icon mobile-whats-app-icon'}><i className="fab fa-whatsapp"/></span>
                                {localStorage.user_token != undefined ?
                                    <span>+8801703945133</span> : null
                                }
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfileSideBar;
