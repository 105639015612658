import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "./Include/UserProfileTopBar";
import UserProfileSideBar from "./Include/UserProfileSideBar";
import UserProfileMainContent from "./Include/UserProfileMainContent";
import $ from "jquery";
import {useParams} from "react-router-dom";
import axios from "axios";
import Cart from "./Include/Cart";
import {Helmet} from "react-helmet";
const InvestmentMain = () => {

    const [slug, set_slug] = useState(null)
    const [round_id, set_round_id] = useState(null)
    const [frsr_id, set_frsr_id] = useState(null)

    const [category, setCategory] = useState([])
    const [number_category, set_number_category] = useState([])
    const [round_id_database, set_round_id_database] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const  params = useParams()

    useEffect(()=>{
        set_slug(params.slug)
        set_slug(params.round_id)
        set_slug(params.frsr_id)
        getNumbers()

        if (params.slug == 'bet-25' && localStorage.bet_25_number != undefined){
            setCategory(JSON.parse(localStorage.bet_25_number).numbers)
            set_number_category(JSON.parse(localStorage.bet_25_number))
            set_round_id_database(JSON.parse(localStorage.bet_25_number).round_id)
        }else if(params.slug == 'bet-100' && localStorage.bet_100_number != undefined){
            setCategory(JSON.parse(localStorage.bet_100_number).numbers)
            set_number_category(JSON.parse(localStorage.bet_100_number))
            set_round_id_database(JSON.parse(localStorage.bet_100_number).round_id)
        }else if(params.slug == 'bet-900' && localStorage.bet_900_number != undefined){
            setCategory(JSON.parse(localStorage.bet_900_number).numbers)
            set_number_category(JSON.parse(localStorage.bet_900_number))
            set_round_id_database(JSON.parse(localStorage.bet_900_number).round_id)
        }
    }, [])



    const getNumbers = () => {
        setIsLoading(true)
      axios.post('/api/get-numbers', params ).then(res=>{
          setCategory(res.data.numbers)
          set_number_category(res.data)
          set_round_id_database(res.data.round_id)
          setIsLoading(false)
          if (params.slug == 'bet-25'){
              localStorage.setItem('bet_25_number' , JSON.stringify(res.data))
          }else if(params.slug == 'bet-100'){
              localStorage.setItem('bet_100_number' , JSON.stringify(res.data))
          }else if(params.slug == 'bet-900'){
              localStorage.setItem('bet_900_number' , JSON.stringify(res.data))
          }

      })
    }

    const showDataMain = () => {
        $('#details_data_main').slideDown(100);
    }
    const allClose = () => {
        $('#details_data_main').hide();
    }
    return (
        <div>
            <Helmet>
                <title>Place Bet | Teer 125</title>
            </Helmet>
                <UserProfileTopBar/>
                <div className="container">
                    <div className={'user-profile-main'}>
                        <div className="row">
                            <div className="col-md-3 profile-sidebar-common-class">
                                <UserProfileSideBar/>
                            </div>
                            <div className="col-md-9 position-relative">
                                <UserProfileMainContent number_category={number_category} isLoading={isLoading} round_id_database={round_id_database} params={params}  category={category}/>
                            </div>
                        </div>
                    </div>
                </div>
            <button className={'d-none'} id={'get_numbers_in_investment_page'} onClick={getNumbers}/>
        </div>
    );
};

export default InvestmentMain;
