import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import axios from "axios";
import $ from 'jquery'
import loader from "../../Assets/images/spinner/bars.svg";
import {Helmet} from "react-helmet";

const Login = () => {
    const history = useHistory()

    useState(()=>{
        if (localStorage.user_token != undefined){
            history.push('/dashboard')
        }
    }, [])


    const [input , setInput] = useState({})
    const [errors , setErrors] = useState({})
    const [loginIsLoading , setLoginIsLoading] = useState(false)

    const handleInput = (e) => {
        let name = e.target.name
        setInput(prev=>({...prev , [name] : e.target.value}))
    }

    const login = () => {
        setLoginIsLoading(true)
        axios.post('/api/user-login', input).then(res=>{
            localStorage.setItem('user_token' , res.data.data.token)
            localStorage.setItem('user_name' , res.data.data.name)
            localStorage.setItem('user_email' , res.data.data.email)
            $('#sign_up_model_open_button').trigger('click')
            window.location.reload()
           // history.goBack()
            history.push('/dashboard')
            setLoginIsLoading(false)
        }).catch(errors =>{
            if (errors.response.status === 422){
                setErrors(errors.response.data.errors)
            }
            setLoginIsLoading(false)
        })
    }

    return (
        <div className="container-fluid g-0 overflow-hidden">
            <Helmet>
                <title>Login | Teer 125</title>
            </Helmet>
            <div className="row">
                <div className="col-md-12">
                    <div className="login-form-section">
                        <div className="login-form-inner">
                            <form className={'login-inner-box'}>
                                <div className="mb-3">
                                    <input
                                        type="text"
                                        className="form-control login-form-control"
                                        placeholder={'Email'}
                                        name={'email'}
                                        value={input.email}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ?  Object.entries(errors).map(([key, val]) =>(
                                        <small className="text-danger validation-error-message" key={key}>{key == 'email'? val : ''}</small>
                                    )):null}
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message" key={key}>{key == 'error_msg' ? val : ''}</small>
                                    )) : null}
                                </div>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control login-form-control"
                                        placeholder={'Password'}
                                        name={'password'}
                                        value={input.password}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ?  Object.entries(errors).map(([key, val]) =>(
                                        <small className="text-danger validation-error-message" key={key}>{key == 'password'? val : ''}</small>
                                    )):null}
                                </div>
                                <div className="remember-and-forgot-pass">
                                    <div className="remember">
                                        <input className="form-check-input" type="checkbox"/><span>Remember</span>
                                    </div>
                                    {/* <Link to={'/'} className={'forgot-title ms-4'}>Forgot Your Password?</Link> */}
                                </div>
                                <div className="submit-btn">
                                    <button onClick={login} type={'button'} className={'login-btn'} dangerouslySetInnerHTML={{__html: loginIsLoading ?  `<img src="${loader}" class="cart-loader" alt="loader"/>` :'Login' }}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
