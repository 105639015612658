import React, {useState} from 'react';
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

const PasswordChange = () => {
    const handlePasswordShowEyeOne = (id) => {
        let element = $('#'+id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    };
    const handlePasswordShowEyeTwo = (id) => {
        let element = $('#'+id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }

    const [passwordInput , setPasswordInput] = useState({})

    const handlePasswordInput = (e) => {
        setPasswordInput(prevState => ({...prevState, [e.target.name] : e.target.value}))
    }

    const updatePassword = () => {
      axios.post('/api/user-change-password', passwordInput).then(res=>{
          Swal.fire({
              position: 'top-end',
              icon: 'success',
              toast: true,
              title: res.data.msg,
              showConfirmButton: false,
              timer: 2000
          })
          setPasswordInput({password:'', password_confirmation:''})
      })
    }

    return (
        <div className={'password-section-main'}>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <form>
                        <div className="pass-group mb-3 position-relative">
                            <label>New Password:</label>
                            <input
                                type="password"
                                id={'login_password_one'}
                                className="form-control pass-control"
                                placeholder="New Password"
                                name={'password'}
                                value={passwordInput.password}
                                onChange={handlePasswordInput}
                            />
                            <div className="eye-icon-pass-change" onClick={()=>handlePasswordShowEyeOne('login_password_one')}>
                                <i className={'fas fa-eye'}/>
                            </div>
                        </div>
                        <div className="pass-group mb-3 position-relative">
                            <label>Confirm Password:</label>
                            <input
                                type="password"
                                id={'login_password_two'}
                                className="form-control pass-control"
                                placeholder="Confirm Password"
                                value={passwordInput.password_confirmation}
                                name={'password_confirmation'}
                                onChange={handlePasswordInput}
                            />
                            <div className="eye-icon-pass-change" onClick={()=>handlePasswordShowEyeTwo('login_password_two')}>
                                <i className={'fas fa-eye'}/>
                            </div>
                        </div>
                        <div className="pass-change-button text-center">
                            <button onClick={updatePassword} className={'change-password-btn'} type={'button'}>Change Password</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    );
};

export default PasswordChange;
