import React from 'react';
import HundredResultRound from "./Include/HundredResultRound";
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import {Helmet} from "react-helmet";

const HundredResult = () => {
    return (
        <div>
            <Helmet>
                <title>Result 100 | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                           <HundredResultRound/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default HundredResult;
