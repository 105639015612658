import React from 'react';
import {Link, useHistory} from "react-router-dom";
import SignIn from "../Pages/SignIn/SignIn";
import $ from 'jquery'

const NavBar = () => {
    const history = useHistory()
    const handleLoginModal = (type = 0) => {
        if (type == 1) {
            $('#create_an_account_login_modal').trigger('click')
        } else {
            $('#login_login_modal').trigger('click')
        }

        if (localStorage.user_token != undefined) {
            history.push('/dashboard')
        } else {
            $('#sign_up_model_open_button').trigger('click')


        }
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg navbar-light nav-bar-bg sticky-top">
                <div className="container">
                    <div className="faw">
                        <Link className="navbar-brand custom-nav-bar-brand" to="/">Teer <span className={'custom-color'}>125</span></Link>

                    </div>

                    <a href={'https://wa.me/+916009877616'} target="_blank"
                       className={'top-bar-font-color d-block d-md-none'}>
                        <span className={'top-bar-mail-icon mobile-whats-app-icon'}><i className="fab fa-whatsapp"/></span>
                        {localStorage.user_token != undefined ?
                        <span>IN</span>:null
                        }
                    </a>
                    <a href={'https://wa.me/+8801703945133'} target="_blank"
                       className={'top-bar-font-color d-block d-md-none'}>
                        <span className={'top-bar-mail-icon mobile-whats-app-icon'}><i className="fab fa-whatsapp"/></span>
                        {localStorage.user_token != undefined ?
                        <span>BD</span>:null
                        }
                    </a>

                    <div className="d-flex d-md-none">
                        {localStorage.user_token == undefined ?
                            <div className="extra-menu d-flex justify-content-end me-2">
                                <button className="nav-link hover-effect custom-nav-color" onClick={handleLoginModal}>Login</button>
                                <button className="nav-link hover-effect custom-nav-color" onClick={(e) => handleLoginModal(1)}>Register</button>
                            </div> : null
                        }
                        <button className="navbar-toggler custom-nav-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent">
                            <span className="text-white mobile-menu-toggle-button-2"><i className="fas fa-bars"/> </span>
                        </button>
                    </div>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link className="nav-link hover-effect active custom-active" aria-current="page" to="/">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Home
                                </Link>
                            </li>

                            {localStorage.user_token != undefined ?
                                <li className="nav-item">
                                    <Link className="nav-link hover-effect custom-nav-color" to="/previous-result">
                                        <span/>
                                        <span/>
                                        <span/>
                                        <span/>
                                        History
                                    </Link>
                                </li>:null
                            }
                            <li className="nav-item">
                                <Link className="nav-link hover-effect custom-nav-color" to="/about">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    About
                                </Link>
                            </li>

                            <li className="nav-item" style={{display: localStorage.user_token != undefined ? 'none' : 'block'}}>
                                <button className="nav-link hover-effect custom-nav-color" onClick={handleLoginModal}>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Login
                                </button>
                            </li>

                            <li className="nav-item" style={{display: localStorage.user_token != undefined ? 'none' : 'block'}}>
                                <button className="nav-link hover-effect custom-nav-color" onClick={(e) => handleLoginModal(1)}>
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Register
                                </button>
                            </li>
                        </ul>
                        {localStorage.profile_photo != undefined && localStorage.user_token ?
                            <img onClick={handleLoginModal} src={localStorage.profile_photo} className={'common-navbar-profile-photo'} alt="profile photo"/> :
                            <button className={'main-login-home'} style={{display: localStorage.user_token == undefined ? 'none' : 'block'}} onClick={handleLoginModal}><i
                                className={'fas fa-user-circle'}/></button>
                        }

                        <button className={'d-none'} id={'sign_up_model_open_button'} data-bs-toggle="modal" data-bs-target="#sign-in-modal"/>

                    </div>
                </div>
            </nav>
            <div className="modal fade sign-in-modal" id="sign-in-modal">
                <div className="modal-dialog sign-in-modal-dialog">
                    <div className="modal-content sign-in-modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title new-sign-in-modal-title" id="exampleModalLabel">Login User Account</h5>
                            <button
                                type="button"
                                className="fas fa-times-circle custom-close-button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                id={'login_modal_close'}
                            />
                        </div>
                        <div className="modal-body sign-in-modal-body ">
                            <SignIn/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NavBar;
