import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../../UserDashboard/Include/UserProfileSideBar";
import {Link, useParams} from "react-router-dom";
import axios from "axios";
import {Helmet} from "react-helmet";
import Swal from "sweetalert2";


const TransactionDetails = () => {

    const params = useParams()

    const handleWithdrawCancel = (e, id) => {
        Swal.fire({
            title: 'Are you sure to cancel?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/cancel-withdraw/'+id).then(res => {
                    window.location.reload();
                })
            }
        })
    }

    useEffect(() => {
        getTransactionDetails()
    }, []);

    const [transaction, setTransaction] = useState([])
    const getTransactionDetails = () => {
        axios.get('/api/get-transaction-details/' + params.id).then(res => {
            setTransaction(res.data.data)
        })
    }


    return (
        <div>
            <Helmet>
                <title>Transaction Details | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            {/*<div className="all-transaction">*/}
                            {/*    <div className="row justify-content-center">*/}
                            {/*        <div className="col-md-6 account-balance new-balance">*/}
                            {/*            <div className="acc-add-fund">*/}
                            {/*                <p className={'balance'}>Balance: 5000000 BDT</p>*/}
                            {/*                <div className="withdraw-and-add-fund">*/}
                            {/*                    <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'add-fund'}>Add Fund +</Link></button>*/}
                            {/*                    <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'/payment-withdrew'}>Withdraw</Link> </button>*/}
                            {/*                </div>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="all-transaction-btn">
                                <h4 className={'transaction-title'}>Transaction Details</h4>
                                {/*<div className="button-transaction">*/}
                                {/*    <button className={'transaction-btn'} type={'button'}>All</button>*/}
                                {/*    <button className={'transaction-btn'} type={'button'}>Profit</button>*/}
                                {/*    <button className={'transaction-btn'} type={'button'}>Loss</button>*/}
                                {/*</div>*/}
                            </div>
                            <div className="transaction-table-list-new">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="transaction-details-main">
                                            <h6 className={'text-center trnx-title'}><Link className={'back-icon'} to={'/transaction'}><i className="fas fa-long-arrow-alt-left"/></Link>Transaction
                                                Details</h6>
                                            <div className="row justify-content-center">
                                                <div className="col-md-6">
                                                    <div className="transaction-details-inner">
                                                        <table className="table table-striped">
                                                            <tbody>
                                                            <tr>
                                                                <th scope="row">Type</th>
                                                                <td>: {transaction.type}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Method</th>
                                                                <td>: {transaction.method}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Sent Account</th>
                                                                <td>: {transaction.account_no}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Transaction Id</th>
                                                                <td>: {transaction.transaction_id}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="transaction-details-inner">
                                                        <table className="table table-striped">
                                                            <tbody>
                                                            <tr>
                                                                <th scope="row">Amount</th>
                                                                <td>: &nbsp;
                                                                    {transaction.amount} {localStorage.currency}
                                                                    {transaction.win_amount != null ?
                                                                        <span className={'ms-1 transaction-details-win-amount'}> <i
                                                                            className="fas fa-trophy"/> {transaction.win_amount} {localStorage.currency}</span> : null
                                                                    }

                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Status</th>
                                                                <td>: {transaction.status}</td>
                                                            </tr>
                                                            <tr>
                                                                <th scope="row">Time</th>
                                                                <td>: {transaction.time}</td>
                                                            </tr>
                                                            {transaction.updated_at != null ?
                                                                <tr>
                                                                    <th scope="row">Updated at</th>
                                                                    <td>: {transaction.updated_at} <small>({transaction.updated_at_diff})</small></td>
                                                                </tr> : null
                                                            }

                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {transaction.status == 'Pending' && transaction.type == 'Withdraw'?
                                                    
                                                    <div className={'withdraw_cancel_btn'}>
                                                        <button className={'btn btn-outline-info btn-sm'}
                                                        onClick={(e)=>handleWithdrawCancel(e,transaction.id )}
                                                        >
                                                            Cancel
                                                        </button>   
                                                    </div>
                                                    :null
                                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TransactionDetails;
