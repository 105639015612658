import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../../UserDashboard/Include/UserProfileSideBar";
import MyAccountTopBar from "./MyAccountTopBar";
import MyAccountAddFund from "./MyAccountAddFund";
import $ from "jquery";
import axios from "axios";
import {Helmet} from "react-helmet";
import {useHistory} from "react-router-dom";
import loader from "./../../../../Assets/images/spinner/bars.svg";
import Swal from "sweetalert2";


const Withdrew = () => {
    // const [show, setShow] = useState(false);
    //
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);




    const editInputFiled = (e, id,) => {
        let button = $(`#${id}_button`).text()
        if (button == 'Edit'){
            $(`#${id}_button`).text('Save')
            $(`#${id}_input`).removeAttr('readonly')
            $(`#${id}_overly`).removeClass('input-overly').removeClass('input-overly-transparent')
        }else{
            $(`#${id}_button`).text('Edit')
            $(`#${id}_input`).attr('readonly', true)
            $(`#${id}_overly`).addClass('input-overly-transparent')
        }
    }
    const handleMethodSelect = (e, id) => {

        $(`#bkash_radio`).prop("checked",false)
        $(`#bkash_overly`).addClass('input-overly-transparent').removeClass('input-overly')

        $(`#rocket_radio`).prop("checked",false)
        $(`#rocket_overly`).addClass('input-overly-transparent').removeClass('input-overly')




       $(`#${id}_radio`).prop("checked",true)
       $(`#${id}_overly`).addClass('input-overly').removeClass('input-overly-transparent')
    }
    const [withdraw_show, set_withdraw_show] = useState('none')
    const withdrewShow = () => {
        // $('#withdrew_method').slideDown(100);
       set_withdraw_show('block')
       // $('.new-method-btn').hide();

    }
    const showMethod = () => {
        $('.add-payment-method').slideDown(100);

    }


    const [userDetails, setUserDetails] = useState([]);
    const getUserProfileInfo = () => {
        axios.get('/api/get-user-details').then(res => {
            setUserDetails(res.data.data)
        })
    }

    useEffect(()=>{
        getUserWithdrawNumber()
        getPaymentMethods()
    }, [])
    const [userWithdrawNumbers , setUserWithdrawNumbers ] = useState([])
    const getUserWithdrawNumber = () => {
      axios.get('/api/get-user-withdraw-number').then(res=>{
          setUserWithdrawNumbers(res.data.data)
      })
    }

    const history = useHistory()
    const [input, setInput]= useState({})
    const [error, setError]= useState('')
    const handleInput = (e, account_no = null) => {

        // console.log( e.target.value)
        setInput(prevState => ({...prevState, [e.target.name] : e.target.value}))
        if (account_no != null){
            setInput(prevState => ({...prevState, account_no : account_no}))
        }
    }

    const [isLoading, setIsLoading] = useState(false)
    const savePaymentRequest = () => {
        if(input.amount != undefined && input.payment_method_id != undefined){
            setIsLoading(true)
            axios.post('/api/withdraw-request', input).then(res=>{
               setIsLoading(false)
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    toast: true,
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 2000
                })
                if(res.data.msg != 'Insufficient Balance'){
                history.push('/transaction-details/'+res.data.data.id)
                }
            })
        }
    }

    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentMethodInput, setPaymentMethodInput] = useState({})
    const [userWithdrawMethods, setUserWithdrawMethods] = useState([])

    const getPaymentMethods = () => {
        axios.get('/api/get-payment-methods').then(res=>{
            setPaymentMethods(res.data)
        })
    }

    const handlePaymentMethodInput = (e) => {
        if (e.target.name == 'payment_method_id'){
            if (e.target.value != 0){
                $('#payment_method_input_'+e.target.value).slideDown(10).siblings('.add-payment-method').slideUp(10)
            }else{
                $('.add-payment-method').slideUp(10)
            }
        }

        setPaymentMethodInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const addPaymentMethod = () => {
        axios.post('/api/add-user-payment-method', paymentMethodInput ).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 2000
            })
            getUserWithdrawMethods()
            getUserWithdrawNumber()
            setPaymentMethodInput({account_no:'', payment_method_id :'' })
        })
    }

    const getUserWithdrawMethods = () => {
        axios.get('/api/get-user-payment-method' ).then(res=>{
            setUserWithdrawMethods(res.data)
        })
    }


    const removeWithdrawMethod = (e, id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/remove-withdraw-method/'+id).then(res=>{
                    getUserWithdrawNumber()
                })
            }
        })




    }

    return (
        <div>
            <Helmet>
                <title>Withdraw Fund | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <MyAccountTopBar  title={'Withdraw'}/>
                            <MyAccountAddFund  getUserProfileInfo={getUserProfileInfo} userDetails={userDetails}/>
                            <div className="row justify-content-center">
                                <div className="col-md-12">
                                    <div className="withdrew-section-main">
                                        <div className="row justify-content-center">
                                            <div className="col-xl-6 col-lg-8 col-md-10">
                                                <div className="withdrew-section-inner">
                                                    <h6 className={'text-center withdrew-title'}>Withdrew</h6>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="row  align-items-center custom-flex-withdrew">
                                                                <div className="col-3 p-1">
                                                                    <p className={'custom-amount'} >Amount:</p>
                                                                </div>
                                                                <div className="col-9 p-0">
                                                                    <div className="input-group withdrew-input-group position-relative">
                                                                        <input
                                                                            type="number"
                                                                            className="form-control form-control-sm input-control"
                                                                            placeholder={'Enter Your Amount'}
                                                                            name={'amount'}
                                                                            value={input.amount}
                                                                            onChange={handleInput}
                                                                            max={userDetails.balance}
                                                                        />
                                                                        {error.length > 2 ? <p className={'text-danger amount-error-msg'}>{error}</p>:null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {userWithdrawNumbers.map((withdraw, index)=>(
                                                                <div className="row input-border p-1 mt-3 align-items-center position-relative">
                                                                    {/*<div id={'bkash_overly'} className={'input-overly-transparent'} onClick={(e)=>handleMethodSelect(e, 'bkash')}/>*/}
                                                                    {/*<div  className={'input-overly-transparent'}/>*/}
                                                                    <div className="col-12 position-relative">
                                                                        <div className="form-check input-check align-items-center withdraw-method-items">
                                                                            <div className="account-title">
                                                                                <div className={'d-flex'}>
                                                                                    <input
                                                                                        onChange={(e)=>handleInput(e, withdraw.account_no)}
                                                                                        name='payment_method_id'
                                                                                        value={withdraw.payment_method_id}
                                                                                        className="form-check-input custom-form-check"
                                                                                        type="radio"
                                                                                    />
                                                                                    <div className={'payment-method-name'}>{withdraw.payment_method}</div>
                                                                                    <div className={'payment-method-number'}>: {withdraw.account_no}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div onClick={(e)=>removeWithdrawMethod(e, withdraw.id)} className={'payment-method-remove'}><button className={'btn btn-light btn-sm'}>-</button></div>

                                                                    </div>
                                                                    {/*<div className="col-9 p-0">*/}
                                                                    {/*    <div className="input-flex">*/}
                                                                    {/*        <input type="number" defaultValue={withdraw.account_no} className="form-control-sm form-control input-control" id={'bkash_input'} readOnly/>*/}
                                                                    {/*        <span id={'bkash_button'} className="input-group-text input-group-edit" onClick={(e)=>editInputFiled(e, 'bkash')}>Edit</span>*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                </div>
                                                            ))}


                                                            <div className="row  justify-content-center">
                                                                    <div className="col-md-12">
                                                                        <div className="add-method-btn text-center">
                                                                            <button type={'button'}  className={'new-method-btn'} onClick={withdrewShow}>+</button>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                            <div className="row" style={{display: withdraw_show}}>
                                                                <div className="col-md-12 p-0">
                                                                    <div className="text-control withdrew-method-section text-center">
                                                                        <h5 className={'withdrew-heading'}>Withdrew Method</h5>
                                                                        <div className="withdrew-flex">
                                                                            <select onChange={handlePaymentMethodInput} value={paymentMethodInput.payment_method_id} name={'payment_method_id'} className="form-select withdrew-select">
                                                                                <option value={0}  selected>Select</option>
                                                                                {paymentMethods.map((method, index)=>(
                                                                                    <option key={index} value={method.id}>{method.name}</option>
                                                                                ))}
                                                                            </select>
                                                                            {/*<button type={'button'} className={'add-method'} onClick={showMethod}>Add</button>*/}
                                                                        </div>
                                                                        {paymentMethods.map((method, index)=>(
                                                                            <div key={index} id={`payment_method_input_${method.id}`} className="add-payment-method" style={{display: 'none'}}>
                                                                                <label className="">{method.name}:</label>
                                                                                <input
                                                                                    className={'payment-control'}
                                                                                    type="text"
                                                                                    name={`account_no`}
                                                                                    value={paymentMethodInput.account_no}
                                                                                    onChange={handlePaymentMethodInput}
                                                                                />
                                                                                <span onClick={addPaymentMethod} className={'minus-account'} dangerouslySetInnerHTML={{__html: 'Add'}}/>
                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                            </div>


                                                            <div className="place-request-btn text-center">
                                                                <button onClick={savePaymentRequest} className={'payment-request-btn'} dangerouslySetInnerHTML={{__html: isLoading ?`<img src="${loader}" class="cart-loader" alt="loader"/>` : 'Place Request'}}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Withdrew;
