import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import $ from 'jquery';
import AddPayment from "../../MyAccount/Includes/AddPayment";
import AddPaymentMobile from "../../MyAccount/Includes/AddPaymentMobile";
import profilePic from '../../../../Assets/images/clint/demo.jpg'
import axios from "axios";
import Swal from "sweetalert2";
import Cart from "./Cart";

const UserProfileTopBar = () => {

    useEffect(() => {
        getUserProfileInfo()
    }, [])

    const [userDetails, setUserDetails] = useState([])

    const getUserProfileInfo = () => {

        axios.get('/api/get-user-details').then(res => {
            setUserDetails(res.data.data)
            localStorage.setItem('currency', res.data.data.currency)
            localStorage.setItem('profile_photo', res.data.data.photo)
            localStorage.setItem('balance', res.data.data.balance)
            localStorage.setItem('deposit_amount', res.data.data.deposit_amount)
            localStorage.setItem('withdrawal_amount', res.data.data.withdrawal_amount)
            localStorage.setItem('win_amount', res.data.data.win_amount)
            localStorage.setItem('running_bet_amount', res.data.data.running_bet_amount)
            localStorage.setItem('bet_amount', res.data.data.bet_amount)
            localStorage.setItem('today_win_amount', res.data.data.today_win_amount)
            localStorage.setItem('account_id', res.data.data.account_id)
        })

    }


    const showLogout = () => {
        $('#account_log_out').toggle(300);
        $('#navbar_main_section').hide();
    }
    const mainMenuShow = () => {
        $('#navbar_main_section').toggle(300);
        $('#account_log_out').hide();

    }
    const showLogouts = () => {
        $('#account_log_outs').toggle(300);
    }
    const history = useHistory()
    const logout = () => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You will be logout and redirect to home",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, logout!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/user-logout').then(res => {
                    localStorage.clear();
                    history.push('/')
                    window.location.reload()
                })
            }
        })


    }


    const [profilePhoto, setProfilePhoto] = useState(localStorage.profile_photo != undefined ? localStorage.profile_photo : profilePic)


    return (
        <>
            {/*design for mobile device start*/}
            <div className={'user-top-bar-main-section d-block d-md-none'}>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="user-top-bar-inner">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="responsive-menu-icon">
                                            <div className="mobile-menu-icon">
                                                <label className={'menu-icon-label'} htmlFor="check">
                                                    <input type="checkbox" id="check" onClick={mainMenuShow}/>
                                                    <span className={'mobile-span'}/>
                                                    <span className={'mobile-span'}/>
                                                    <span className={'mobile-span'}/>
                                                </label>
                                            </div>
                                            <div className="user-top-bar-left-mobile">
                                                <Link className="custom-nav-bar-brand" to="/dashboard">Teer <span
                                                    className={'custom-color'}>125</span></Link>
                                            </div>
                                            <div className="top-bar-mobile-balance-area">
                                                <p className={'bdt-balance'}><span>{localStorage.balance != undefined ? localStorage.balance : userDetails.balance} {localStorage.currency}</span></p>
                                                    <button className={'add-fund-btn'} type={"button"} data-bs-toggle="modal" data-bs-target="#payment_method-mobile">Add Fund +</button>
                                            </div>
                                            <div className={'user-top-bar-icon ms-2'} onClick={showLogout}>
                                                <img src={profilePhoto} alt="profile-picture"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="account-logout" id={'account_log_out'} style={{display: 'none'}}>
                        <div className="profile-section">
                            <Link to={'/my-account'}>{userDetails.name}</Link>
                            <Link to={'/my-account'}>My Account</Link>
                            {/* <Link to={'/change-password'}>Change Password</Link> */}
                            <button onClick={logout}>Log Out</button>
                        </div>
                        <div className="balance-add-mobile">
                            <div className="amount">
                                <p className={'bdt-balance'}><span>{localStorage.balance != undefined ? localStorage.balance : userDetails.balance} {localStorage.currency}</span></p>
                            </div>
                            <button className={'add-fund-btn'} type={"button"} data-bs-toggle="modal" data-bs-target="#payment_method-mobile">Add Fund +</button>
                        </div>
                    </div>
                </div>
                <AddPaymentMobile/>
            </div>
            {/*design for mobile device end*/}


            {/*design for desktop view start*/}
            <div className={'user-top-bar-main-section d-none d-md-block'}>
                <div className="container position-relative">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="user-top-bar-inner">
                                <div className="row align-items-center">
                                    <div className=" col-sm-8 col-12 ">
                                        <div className="d-flex align-items-center">
                                            <Link className="custom-nav-bar-brand" to="/dashboard">Teer <span
                                                className={'custom-color'}>125</span></Link>
                                                
                                                <a href={'https://wa.me/+916009877616'} target="_blank"
                                                className={'top-bar-font-color d-none d-md-flex ms-3'}>
                                                 <span className={'top-bar-mail-icon mobile-whats-app-icon'}><i className="fab fa-whatsapp"/></span>
                                                 {localStorage.user_token != undefined ?
                                                     <span>+916009877616</span> : null
                                                 }
                                             </a>
                                            <span className={'mx-2'}>|</span>
                                            <a href={'https://wa.me/+8801703945133'} target="_blank"
                                                className={'top-bar-font-color'}>
                                                <span className={'top-bar-mail-icon'}><i className="fab fa-whatsapp"/></span>
                                                +8801703945133
                                            </a>
                                        </div>





                                    </div>
                                    <div className=" col-sm-4 col-12 ">
                                        <div className="user-top-bar-right">
                                            <div className="balance-add">
                                                <div className="amount">
                                                    <p className={'bdt-balance'}><span>{localStorage.balance != undefined ? localStorage.balance : userDetails.balance} {localStorage.currency}</span></p>
                                                </div>
                                                <button className={'add-fund-btn'} type={"button"} data-bs-toggle="modal" data-bs-target="#payment_method">Add Fund +</button>
                                            </div>
                                            <div className={'user-top-bar-icon ms-2'} onClick={showLogouts}>
                                                <img src={profilePhoto} alt="profile-picture"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="account-logout" id={'account_log_outs'} style={{display: 'none'}}>
                        {/*<div className="profile-picture">*/}
                        {/*    <img src={profilePhoto} alt="profile-picture" onClick={uploadProfilePhoto}/>*/}
                        {/*    <input onChange={uploadImage} type={'file'} className={'d-none'} id={'profile_img_upload'}/>*/}
                        {/*</div>*/}
                        <Link to={'/my-account'}>{userDetails.name}</Link>
                        <Link to={'/my-account'}>My Account</Link>
                        {/* <Link to={'/change-password'}>Change Password</Link> */}
                        <button onClick={logout}>Log Out</button>
                    </div>
                </div>
                <AddPayment/>
            </div>
            {/*design for desktop view end*/}
            <Cart/>
            <button className={'d-none'} onClick={getUserProfileInfo} id={'top_bar_get_profile_data'}/>
        </>

    );
};

export default UserProfileTopBar;
