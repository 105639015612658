import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Countdown from 'react-countdown';
// import $ from "jquery";
import TeerAllResult from "../../AllResult/TeerAllResult";
import axios from "axios";
import loader from "../../../../Assets/images/spinner/ripple.svg";

const HundredResultRound = () => {


    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getCategoryDetails = () => {
        setIsLoading(true)
        axios.get('/api/get-category-details/2').then(res => {
            setCategory(res.data.data)
            setIsLoading(false)
            // setCounterEnd(false)
            localStorage.setItem('category_2' , JSON.stringify(res.data.data))
        })
    }

    useEffect(() => {
        getCategoryDetails()
        if (localStorage.category_2 != undefined){
            setCategory(JSON.parse(localStorage.category_2))
        }
        setInterval(()=>{
            getCategoryDetails()
        }, 10000)


    }, [])

    const [counterEnd, setCounterEnd] = useState(false)

    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state

            // getCategoryDetails()
            setCounterEnd(true)
            setCounter(false)

            return null;
        } else {
            // Render a countdown
            return <span className={'hundred-count-down'}>
                  <span className={'count-min'}>{hours}</span>:
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };

    useEffect(() => {
        setCounter(true)

    }, [])

    useEffect(()=>{
        getCategoryDetails()
        console.log(counterEnd)
    }, [counterEnd])


    const [counter, setCounter] = useState(false)

    const [investmentModal, setInvestmentModal] = useState(false)
    const handleInvestmentModal = () => {
        setInvestmentModal(true)
    }


    return (
        <div className="hundred-result-round-main position-relative">
            {isLoading  && localStorage.category_2 == undefined?
                <div className="investment-page-loader">
                    <img src={loader} alt={'loader'}/>
                </div> : null
            }
            <div className="row">
                <div className="col-md-12">
                    <h1 className={'text-center hundred-result-heading'}>SHILLONG TEER</h1>
                </div>
            </div>
            <div className="result-title">
                {Object.keys(category).length > 0 && category.area != undefined ?  category.area.map((area, index) => (
                    < div className={'result-border'} key={index}>
                        <div className="row">

                            <div className="col-md-12">
                                <h3 className={'text-center result-header'}>{area.name}</h3>
                            </div>

                            {area.frsr != undefined ? area.frsr.map((result, resultIndex) => (
                                <div className="col-sm-6 justify-content-center" key={resultIndex}>
                                    <div className="hundred-card">
                                        <p className="hundred-card-text">{result.name}</p>
                                        <div className="hundred-card-body">
                                            <h5 className="hundred-card-title">{result.time}</h5>
                                            <div className="invest-and-result-count">
                                                <div className="count-number">
                                                    <Countdown
                                                        date={result.expire ? Date.now() : result.raw_time}
                                                        // date={Date.now() + item.raw_time}
                                                        renderer={renderer}
                                                    />
                                                    {!result.expire?
                                                        <Link to={`/invest/${category.slug}/${area.id}/${result.id}`} className="hundred-button invest-button">Bet Now</Link> : null
                                                    }


                                                    {/*{result.expire ?*/}
                                                    {/*    <div className="final-result">*/}
                                                    {/*        <p><span className={'winner-icon'}><i className="fas fa-trophy"/></span>{result.result}</p>*/}
                                                    {/*    </div> : null*/}
                                                    {/*}*/}
                                                    {result.expire ?
                                                        <div className="final-result">
                                                            <p><span className={'winner-icon'}><i className="fas fa-trophy"/></span> <span dangerouslySetInnerHTML={{__html: result.result != null ? result.result : '<span class="spinner-border spinner-border-sm"></span>'}}/></p>
                                                        </div> : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )):null}
                        </div>
                    </div>
                )):null}
            </div>
            <div className="bet-now-btn-section">
                {/*<button className={'hundred-bet-now-btn'} type={"button"} onClick={handleInvestmentModal}>Invest Now</button>*/}
            </div>
            {/*Teer Slide Section Start*/}
            <TeerAllResult investmentModal={investmentModal}/>
            {/*Teer Slide Section End*/}


        </div>


    )
        ;
};

export default HundredResultRound;
