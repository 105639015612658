import React, {useEffect, useState} from 'react';
import 'react-tabs/style/react-tabs.css';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
import $ from 'jquery'
import {useHistory} from "react-router-dom";
import axios from 'axios'
import loader from './../../../Assets/images/spinner/bars.svg'
import cartLoader from "../../../Assets/images/spinner/bars.svg";
import Swal from "sweetalert2";

const SignIn = () => {
    const history = useHistory()

    const [input, setInput] = useState({})
    const [passInput, setPassInput] = useState('')
    const [errors, setErrors] = useState({})

    useEffect(() => {
        setInput(prev => ({...prev, country_id: 1}))
    }, [])

    const handleInput = (e) => {
        let name = e.target.name
        setInput(prev => ({...prev, [name]: e.target.value}))
    }
    const register = () => {
        setLoginIsLoading(true)
        axios.post('/api/user-register', input).then(res => {
            localStorage.setItem('user_token', res.data.token)
            localStorage.setItem('user_name', res.data.user.name)
            localStorage.setItem('user_email', res.data.user.email)
            $('#sign_up_model_open_button').trigger('click')
            setTimeout(() => {
                window.location.reload()
            }, 1000)
            history.push('/dashboard')
            setLoginIsLoading(false)
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
            }
            setLoginIsLoading(false)
        })
    }

    const handleLoginToggle2 = (id2) => {
        if (id2 == 'account') {
            $('#sign_in_email_otp_btn_section').slideUp()
            $('#sign_up_section').slideDown()
        } else if (id2 == 'sign_in') {
            $('#sign_in_email_otp_btn_section').slideDown()
            $('#sign_up_section').slideUp()
        } else {
            $('#sign_in_email_otp_btn_section').slideUp()
            $('#forgot_section').slideDown()
        }
    }
    const handlePasswordShow = (id) => {
        let element = $('#' + id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }

    const [loginIsLoading , setLoginIsLoading] = useState(false)
    const login = () => {
        setLoginIsLoading(true)
        axios.post('/api/user-login', input).then(res => {
            localStorage.setItem('user_token', res.data.data.token)
            localStorage.setItem('user_name', res.data.data.name)
            localStorage.setItem('user_email', res.data.data.email)
            $('#sign_up_model_open_button').trigger('click')

            setTimeout(() => {
                window.location.reload()
            }, 1000)
            history.push('/dashboard')
           setLoginIsLoading(false)
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
            }
           setLoginIsLoading(false)
        })
    }
const [isForgetPasswordLoading, setIsForgetPasswordLoading]= useState(false)
const handleForgotPass = (e) => {
        if (passInput.length > 5){
            setIsForgetPasswordLoading(true)
            axios.post('/api/user-forget-password?user='+passInput).then(res => {
                $('#sign-in-modal').trigger('click')
                setIsForgetPasswordLoading(false)
                //$('#create_an_account_login_modal').trigger('click')
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                })
            }).catch(errors=>{
                if (errors.response.status==422){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: errors.response.data.password[0],
                        showConfirmButton: false,
                        timer: 3000,
                        toast: true
                    })
                }
            })
        }else{
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title:'Please enter a valid information',
                showConfirmButton: false,
                timer: 3000,
                toast: true
            })
        }

}
const forgetPassChange = (e) => {
    setPassInput(e.target.value)
}
    return (
        <div className="signin-container">
            <div className="sign-in-email-otp-btn-section" id={'sign_in_email_otp_btn_section'}>
                <div className="singin-with-email" id={'sign_in_with_email'}>
                    <div className="sign-in-form">
                        <form>
                            <div className="position-relative">
                                <input
                                    className={'sign-in-form-control form-control'}
                                    type="text"
                                    placeholder="Enter Your Mobile Number/Eamil/Username/User-ID"
                                    onChange={handleInput}
                                    name={'email'}
                                    value={input.email}
                                />
                                {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                    <small className="text-danger validation-error-message" key={key}>{key == 'error_msg' ? val : ''}</small>
                                )) : null}
                                {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                    <small className="text-danger validation-error-message" key={key}>{key == 'email' ? val : ''}</small>
                                )) : null}
                            </div>
                            <div className="position-relative">

                                <div className="password-with-eye-icon">
                                    <input
                                        id={'login_password'}
                                        className={'sign-in-form-control form-control'}
                                        type="password"
                                        placeholder="Enter Your Password new"
                                        onChange={handleInput}
                                        name={'password'}
                                        value={input.password}
                                    />
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message" key={key}>{key == 'password' ? val : ''}</small>
                                    )) : null}
                                    <div className="eye-icon" onClick={() => handlePasswordShow('login_password')}>
                                        <i className={'fas fa-eye'}/>
                                    </div>
                                </div>
                            </div>


                            <div className="sign-in-and-forgot-pass">
                                <div className="chek-box-sign-in">
                                    <Toggle
                                        icons={false}
                                        value='yes'/>
                                    <span className={'remember'}>Remember Me</span>
                                </div>
                                <div className="forgot-password">
                                    <p type={"button"} className={'forgot-pass'} onClick={() => handleLoginToggle2('forgot')}>Forget your
                                        password?</p>
                                </div>
                            </div>
                            <div className={'text-center email-and-phone-sign-in'}>
                                <button onClick={login} type={'button'} dangerouslySetInnerHTML={{__html: loginIsLoading ?  `<img src="${loader}" class="cart-loader" alt="loader"/>` :'Sign In' }} className="sign-in-btn-new" />
                            </div>
                        </form>
                        <div className="not-registered">
                            <p>Not registered? <span id={'create_an_account_login_modal'} className={'create-account'} onClick={() => handleLoginToggle2('account')}>Create an account</span>
                            </p>
                        </div>
                        {/* <div className="sign-in-with-social-media-icon">
                            <p className={'or-sign-in-with'}>Or Sign In With</p>
                            <div className="s-icon-section">
                                <i className={'fab fa-google s-media-icon'}/>
                                <i className={'fab fa-facebook s-media-icon'}/>
                                <i className={'fab fa-whatsapp s-media-icon'}/>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="sign-up-section" id={'sign_up_section'} style={{display: 'none'}}>
                <div className="sign-in-form">
                    <form>
                        <div className="position-relative">
                            <input
                                className={'sign-in-form-control form-control'}
                                type="text"
                                placeholder="Enter Your Name"
                                value={input.name}
                                name={'name'}
                                onChange={handleInput}
                            />
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'name' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className="position-relative">
                            <input
                                className={'sign-in-form-control form-control'}
                                type="number"
                                placeholder="Enter Your Phone Number"
                                value={input.phone}
                                name={'phone'}
                                onChange={handleInput}
                            />
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'phone' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className="position-relative">
                            <input
                                className={'sign-in-form-control form-control'}
                                type="text"
                                placeholder="Enter Your Username"
                                value={input.username}
                                name={'username'}
                                onChange={handleInput}
                            />
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'username' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className="position-relative">
                            <input
                                className={'sign-in-form-control form-control'}
                                type="email"
                                placeholder="Enter Your Email"
                                value={input.email}
                                name={'email'}
                                onChange={handleInput}
                            />
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'email' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className="password-with-eye-icon">
                            <input
                                id={'create_password'}
                                className={'sign-in-form-control form-control'}
                                type="password"
                                placeholder="Enter Your Password"
                                value={input.password}
                                name={'password'}
                                onChange={handleInput}
                            />

                            <div className="eye-icon" onClick={() => handlePasswordShow('create_password')}>
                                <i className={'fas fa-eye'}/>
                            </div>
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'password' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className="position-relative">
                            <select
                                className="form-select sing-up-form-select"
                                value={input.country_id}
                                name={'country_id'}
                                onChange={handleInput}
                            >
                                <option selected value={'1'}>Bangladesh</option>
                                <option value={'2'}>India</option>
                                <option value={'3'}>Bhutan</option>
                                <option value={'4'}>Nepal</option>
                                <option value={'5'}>Singapore</option>
                            </select>
                            {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                <small className="text-danger validation-error-message" key={key}>{key == 'country_id' ? val : ''}</small>
                            )) : null}
                        </div>
                        <div className={'text-center email-and-phone-sign-in'}>
                            <button onClick={register} type={'button'} className="sign-in-btn-new" dangerouslySetInnerHTML={{__html: loginIsLoading ?  `<img src="${loader}" class="cart-loader" alt="loader"/>` :'Create' }} />
                        </div>
                    </form>
                    <div className="not-registered">
                        <p>If AllReady registered? <span id={'login_login_modal'} className={'create-account'}  onClick={() => handleLoginToggle2('sign_in')}>Sign In</span>
                        </p>
                    </div>
                </div>
                {/* <div className="sign-in-with-social-media-icon">
                    <p className={'or-sign-in-with'}>Or Sign Up With</p>
                    <div className="s-icon-section">
                        <i className={'fab fa-google s-media-icon'}/>
                        <i className={'fab fa-facebook s-media-icon'}/>
                        <i className={'fab fa-whatsapp s-media-icon'}/>
                    </div>
                </div> */}
            </div>

            <div className="sign-up-section" id={'forgot_section'} style={{display: 'none'}}>
                <div className="sign-in-form">
                    <form>
                        <input
                            className={'sign-in-form-control form-control'}
                            type="text"
                            placeholder="Enter Your Mobile Number/Eamil/Username/User-ID"
                            name={'password'}
                            value={passInput.password}
                            onChange={forgetPassChange}
                        />

                        <div className={'text-center email-and-phone-sign-in'}>
                            <button type={'button'} className="sign-in-btn-new"  onClick={handleForgotPass}  dangerouslySetInnerHTML={{__html: isForgetPasswordLoading ?  `<img src="${loader}" class="cart-loader" alt="loader"/>` :'Send Link' }}/>
                        </div>
                    </form>
                </div>
                {/* <div className="sign-in-with-social-media-icon">
                    <p className={'or-sign-in-with'}>Or Sign Up With</p>
                    <div className="s-icon-section">
                        <i className={'fab fa-google s-media-icon'}/>
                        <i className={'fab fa-facebook s-media-icon'}/>
                        <i className={'fab fa-whatsapp s-media-icon'}/>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default SignIn;
