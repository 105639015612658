import React from 'react';
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';
const NewsTicker = () => {
    return (
        <div className={'container-fluid head-line-bgcolor '}>
            <div className="container text-center font-size-news-ticker">
                <Typed
                    strings={['Welcome to TEER 125 Online Betting Game']}
                    typeSpeed={40}
                    backSpeed={40}
                    stopped={false}
                    loop={true}
                />
            </div>
        </div>
    );
};

export default NewsTicker;
