import React from 'react';

const MyAccountTopBar = (props) => {
    return (
        <div className="row">
            <div className="col-md-12">
                <h5 className={'text-center header-my-account'}>{props.title}</h5>
            </div>
            <div className="col-md-12">
            <h5 className={'text-center header-my-account_balance'}>
            <h5>Balance</h5>
               <hr className={'dashboard-hr'}/>
                {localStorage.balance != undefined ? localStorage.balance : 0}<span className={'span'}>{localStorage.currency}</span></h5>
            </div>
        </div>
    );
};

export default MyAccountTopBar;
