import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useHistory} from "react-router-dom";
import $ from "jquery";

const AddPaymentMobile = () => {

    useEffect(() => {
        getPaymentMethods()
    }, []);


    const [payment_methods , set_payment_methods] = useState([])
    const getPaymentMethods = () => {
        axios.get( '/api/get-payment-methods').then(res=>{
            set_payment_methods(res.data)
        })
    }

    const [input , setInput] = useState({})
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name] : e.target.value }))
    }

    const history = useHistory()
    const addPayment = () => {
        $('#payment_method-mobile').trigger('click')
        if (input.payment_method_id == 1){
            history.push('/bkash-payment/'+input.amount)
        }else if (input.payment_method_id == 2){
            history.push('/nagad-payment/'+input.amount)
        }else if(input.payment_method_id == 3){
            history.push('/rocket-payment/'+input.amount)
        }else if(input.payment_method_id == 4){
            history.push('/paytm-payment/'+input.amount)
        }else if(input.payment_method_id == 5){
            history.push('/gpay-payment/'+input.amount)
        }
    }
    return (

    <div className="modal fade" id="payment_method-mobile">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content add-payment-modal-content">
                <div className="modal-body">
                    <label className="form-label custom-form-label">Payment Method</label>
                    <select
                        className="form-select form-select-lg payment-method-delivery-point mb-3"
                        name={'payment_method_id'}
                        value={input.payment_method_id}
                        onChange={handleInput}
                    >
                        <option value={0} selected>Select Method</option>
                        {payment_methods.map((method, index)=>(
                            <option key={index} value={method.id}>{method.name}</option>
                        ))}
                    </select>
                    <div className="mb-2">
                        <label className="form-label custom-form-label">Amount (BDT)</label>
                        <input
                            type="number"
                            className="form-control delivery-form-control"
                            placeholder="20000"
                            name={'amount'}
                            value={input.amount}
                            onChange={handleInput}
                        />
                    </div>
                    <div className="custom-modal-footer">
                        <button onClick={addPayment} type="button" className="btn btn-outline-success btn-font-size">Add Payment</button>
                        <button type="button" className="btn btn-outline-danger btn-font-size" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    );
};

export default AddPaymentMobile;
