import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../../UserDashboard/Include/UserProfileSideBar";
import MyAccountTopBar from "./MyAccountTopBar";
import MyAccountAddFund from "./MyAccountAddFund";
import {useHistory} from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import {Helmet} from "react-helmet";

const AddFund = () => {


    const [userDetails, setUserDetails] = useState([]);
    const getUserProfileInfo = () => {
        axios.get('/api/get-user-details').then(res => {
            setUserDetails(res.data.data)
        })
    }

    useEffect(() => {
        getPaymentMethods()
    }, []);


    const [payment_methods, set_payment_methods] = useState([])
    const getPaymentMethods = () => {
        axios.get('/api/get-payment-methods').then(res => {
            set_payment_methods(res.data)
        })
    }

    const [input, setInput] = useState({})
    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const history = useHistory()
    const addPayment = () => {
      //  $('#payment_method').trigger('click')
        $('#payment_method').trigger('click')
        if (input.payment_method_id == 1){
            history.push('/bkash-payment/'+input.amount)
        }else if (input.payment_method_id == 2){
            history.push('/nagad-payment/'+input.amount)
        }else if(input.payment_method_id == 3){
            history.push('/rocket-payment/'+input.amount)
        }else if(input.payment_method_id == 4){
            history.push('/paytm-payment/'+input.amount)
        }else if(input.payment_method_id == 5){
            history.push('/gpay-payment/'+input.amount)
        }
    }
    return (
        <div>
            <Helmet>
                <title>Add Fund | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <MyAccountTopBar title={'Add Fund'}/>
                            <MyAccountAddFund getUserProfileInfo={getUserProfileInfo} userDetails={userDetails}/>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="add-fund-section-main">
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className="add-fund-content-inner">
                                                    <label className="form-label custom-form-label">Payment Method</label>
                                                    <select
                                                        className="form-select form-select-lg payment-method-delivery-point mb-3"
                                                        name={'payment_method_id'}
                                                        value={input.payment_method_id}
                                                        onChange={handleInput}
                                                    >
                                                        <option value={0} selected>Select Method</option>
                                                        {payment_methods.map((method, index) => (
                                                            <option key={index} value={method.id}>{method.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="mb-3">
                                                        <label className="form-label custom-form-label">Amount ({localStorage.currency})</label>
                                                        <input
                                                            type="number"
                                                            className="form-control delivery-form-control"
                                                            placeholder="20000"
                                                            name={'amount'}
                                                            value={input.amount}
                                                            onChange={handleInput}
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <button onClick={addPayment} type="button" className="btn btn-outline-success btn-font-size">Add Payment</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AddFund;
