import React from 'react';
import {Link} from "react-router-dom";

const TicketTable = () => {


    const columns = [
        {
            name: 'Title',
            selector: row => row.title,
        },
        {
            name: 'Year',
            selector: row => row.year,
        },
        {
            name: 'Naim',
            selector: row => row.naim,
        },
        {
            name: 'Asu',
            selector: row => row.asu,
        },
    ];

    const data = [
        {
            id: 1,
            title: 'Beetlejuice asdf asdf asdf asdf asdf asdf asdf asdf',
            year: '1988',
            naim: '1988',
            asu: '1988',
        },
        {
            id: 2,
            title: 'Ghostbusters as asdf asdf asdf asdf asdf s asdf',
            year: '1984',
            naim: '1988',
            asu: '1988',
        },
    ]

    return (
        <div>
            <div className="all-transaction-table">
                <div className="show-ticket">
                    <div className="main-all-order-section">
                        <label className={'all-order-section-label'}>Show:</label>
                        <select className={' form-select all-order-select-section'}>
                            <option value="" className={'all-order-option-section'}> 10 entries
                            </option>
                            <option value="" className={'all-order-option-section'}>25 entries
                            </option>
                            <option value="" className={'all-order-option-section'}>50 entries
                            </option>
                            <option value="" className={'all-order-option-section'}>All
                            </option>
                        </select>
                    </div>
                    <div className="transaction-list-search-box">
                        <input className="transaction-list-search-box-main" type="text" placeholder="Search"/>
                        <span className={'search-icon-absolute'}><i className="fas fa-search"/></span>
                    </div>
                </div>

                    <div className="table-responsive">
                        <table className="table table-borderless custom-table">
                    <thead className={'ticket-table-heading'}>
                    <tr>
                        <th>Ticket Id</th>
                        <th>Category</th>
                        <th>Subject</th>
                        <th width={'250px'}>Date - Time</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody className={'ticket-table-body'}>
                    <tr>
                        <td><Link to={'/'}>654824</Link></td>
                        <td>Billing Support</td>
                        <td><Link to={'/'}>Affiliate Withdrawal Request</Link></td>
                        <td>
                         20-01-2022 10:15 PM
                        </td>
                        <td><span  className={'ticket-status-one'}>Close</span></td>
                    </tr>
                    <tr>
                        <td><Link to={'/'}>54785</Link></td>
                        <td>Technical Support</td>
                        <td><Link to={'/'}>Support - Testing</Link></td>
                        <td>
                            21-01-2022 10:30 PM
                        </td>
                        <td><span className={'ticket-status-one'}>Close</span></td>
                    </tr>
                    <tr>
                        <td><Link to={'/'}>47584</Link></td>
                        <td>Pre-Sales Support</td>
                        <td><Link to={'/'}>Affiliate Withdrawal Request</Link></td>
                        <td>
                            22-01-2022 11:15 PM
                        </td>
                        <td><span className={'ticket-status-tow'}>In Progress</span></td>
                    </tr>
                    <tr>
                        <td><Link to={'/'}>68754</Link></td>
                        <td>General Enquiries</td>
                        <td><Link to={'/'}>Testing Support</Link></td>
                        <td>
                            24-01-2022 12:15 PM
                        </td>
                        <td><span className={'ticket-status-four'}>Hold on</span></td>
                    </tr>

                    <tr>
                        <td><Link to={'/'}>98754</Link></td>
                        <td>Test</td>
                        <td><Link to={'/'}>Pre-Sales Support</Link></td>
                        <td>
                            25-01-2022 09:15 PM
                        </td>
                        <td><span className={'ticket-status-one'}>Close</span></td>
                    </tr>

                    <tr>
                        <td><Link to={'/'}>35485</Link></td>
                        <td>Technical Support</td>
                        <td><Link to={'/'}>Testing Support</Link></td>
                        <td>
                            30-01-2022 10:15 PM
                        </td>
                        <td> <span className={'ticket-status-three'}>Open</span></td>
                    </tr>
                    </tbody>
                </table>
                    </div>
                <div className="all-transaction-page-info">
                    <div className="data-tables-info">
                        <p>Showing 1 to 3 of 3 entries</p>
                    </div>
                    <div className="data-tables-paginate">
                        <nav>
                            <ul className="pagination">
                                <li className="page-item disabled">
                                    <Link className="page-link" to={'/'}>Previous</Link>
                                </li>
                                <li className="page-item active">
                                    <Link className="page-link" to={'/'}>1</Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to={'/'}>2</Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to={'/'}>3</Link>
                                </li>
                                <li className="page-item">
                                    <Link className="page-link" to={'/'}>Next</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default TicketTable;
