import React, {useState} from 'react';

import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
const Faq = () => {
    const [faq]=useState([
        {title:' What harsh truths do you prefer to ignore?', description:' Exercitation in fugiat est ut ad ea cupidatat ut in\n' +
                '                                        cupidatat occaecat ut occaecat consequat est minim minim\n' +
                '                                        esse tempor laborum consequat esse adipisicing eu\n' +
                '                                        reprehenderit enim.'},
        {title:' Is free will real or just an illusion?',
            description:'  In ad velit in ex nostrud dolore cupidatat consectetur\n' +
                '                                        ea in ut nostrud velit in irure cillum tempor laboris\n' +
                '                                        sed adipisicing eu esse duis nulla non.'},
        {title:' What harsh truths do you prefer to ignore?',
            description:'In ad velit in ex nostrud dolore cupidatat consectetur\n' +
                '                                        ea in ut nostrud velit in irure cillum tempor laboris\n' +
                '                                        sed adipisicing eu esse duis nulla non.'},
        {title:' What harsh truths do you prefer to ignore?',
            description:' Exercitation in fugiat est ut ad ea cupidatat ut in\n' +
                '                                        cupidatat occaecat ut occaecat consequat est minim minim\n' +
                '                                        esse tempor laborum consequat esse adipisicing eu\n' +
                '                                        reprehenderit enim.'},
        {title:' What harsh truths do you prefer to ignore?',
            description:' Exercitation in fugiat est ut ad ea cupidatat ut in\n' +
                '                                        cupidatat occaecat ut occaecat consequat est minim minim\n' +
                '                                        esse tempor laborum consequat esse adipisicing eu\n' +
                '                                        reprehenderit enim.'},

    ])
    return (
        <div className={'container'}>
            <div className="row">
                <div className="col-md-12 ">
                    <div className={'faq-section-main'}>
                        <h6 className={'text-center mb-3'}>FAQ</h6>
                        <Accordion allowZeroExpanded={true}>
                            {faq.map((item,index)=>(
                                <AccordionItem key={index}>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            {item.title}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p className={'faq-details'}>
                                            {item.description}
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>

        </div>

    );
};

export default Faq;
