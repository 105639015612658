import React, {useEffect, useState} from 'react';
import axios from "axios";
import TopBar from "../Include/TopBar";
import NavBar from "../Include/NavBar";
import Slider from "../Include/BannerSlider";
import NewsTicker from "../Include/NewsTicker";
import Footer from "../Include/Footer";
import CopyRight from "../Include/CopyRight";
import Faq from "../Include/Faq";
import InvestmentTwentyFive from "../Include/InvestmentTwentyFive";
import InvestmentHundred from "../Include/InvestmentHundred";
import InvestmentNineHundred from "../Include/InvestmentNineHundred";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

const Home = () => {
    
    const  useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()
    const [loginAsUser , setloginAsUser] = useState(null)

    useState(()=>{
        setloginAsUser(query.get("u"))
    }, [])

    const loginAsUserAction = () => {
            localStorage.clear();
            localStorage.setItem('user_token' , loginAsUser)
            localStorage.setItem('user_email' , query.get("e"))
            localStorage.setItem('user_name' , query.get("n"))
            window.location.replace('/dashboard');
    }

    useEffect(() => {
        if(loginAsUser){
            loginAsUserAction()
        }
    }, [])

    return (
        <div>
            <Helmet>
                <title>Welcome | Teer 125</title>
            </Helmet>
            <TopBar/>
            <NavBar/>
            <NewsTicker/>
            <Slider/>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <InvestmentTwentyFive/>
                    </div>
                </div>
            </div>
            <InvestmentHundred/>
            <InvestmentNineHundred/>
            <Faq/>
            <Footer/>
            <CopyRight/>
        </div>
    );
};

export default Home;
