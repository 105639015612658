import React from 'react';

const CopyRight = () => {
    return (
        <div className={'copy-right-section-main text-center'}>
            <p>Copyrights © 2022. All rights reserved by Teer-125</p>
        </div>
    );
};

export default CopyRight;
