import React, {useEffect, useState} from 'react';
import Countdown from "react-countdown";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";

const TeerAllResult = (props) => {

    // const investShow = () => {
    //     $('#all_invest_show').slideDown(300);
    // }

    const renderer_white = ({minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            setCounterEnd(true)
            setCounter(false)
            return null;
        } else {
            // Render a countdown
            return <span className={'hundred-count-down-white'}>
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };

    // const renderer = ({minutes, seconds, completed}) => {
    //     if (completed) {
    //         // Render a completed state
    //         setCounterEnd(true)
    //         setCounter(false)
    //         return null;
    //     } else {
    //         // Render a countdown
    //         return <span className={'hundred-count-down'}>
    //             <span className={'count-min'}>{minutes}</span>
    //         :<span className={'count-sec'}>{seconds}</span></span>;
    //     }
    // };
    const [counterEnd, setCounterEnd] = useState(false)
    useEffect(() => {
        setCounter(true)
    }, [])
    const [counter, setCounter] = useState(false)

    const [round] = useState([
        {
            roundTitle: '1st Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
        {
            roundTitle: '2nd Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
        {
            roundTitle: '3rd Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
        {
            roundTitle: '4th Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
        {
            roundTitle: '5th Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
        {
            roundTitle: '6th Round',
            time: '10:15 Pm',
            winingNumber: 10
        },

        {
            roundTitle: '7th Round',
            time: '10:15 Pm',
            winingNumber: 10
        },

        {
            roundTitle: '8th Round',
            time: '10:15 Pm',
            winingNumber: 10
        },
    ]);

    const [hundred] = useState([
        {
            title: 'Shillong Teer Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
        {
            title: 'Khanapara Teer Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
        {
            title: 'Night Teer Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
        {
            title: 'Night Teer 2 Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
        {
            title: 'Bhutan Teer  Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
        {
            title: 'Meghalaya Teer  Results',
            results: [
                {
                    name: 'FR',
                    time: '10:PM',
                    winNumber: 20
                },
                {
                    name: 'SR',
                    time: '10:PM',
                    winNumber: 25
                }

            ]
        },
    ]);

    const [ninetyNineHundred] = useState([
        {
            roundTitle: '1st Round',
            time: '10:15 Pm',
            winingNumber: 500
        },
        {
            roundTitle: '2nd Round',
            time: '10:15 Pm',
            winingNumber: 800
        },
        {
            roundTitle: '3rd Round',
            time: '10:15 Pm',
            winingNumber: 300
        },
    ]);

    const [investmentModal, setInvestmentModal] = useState(false)
    const handleInvestmentModal = () => {
        investmentModal ? setInvestmentModal(false): setInvestmentModal(true)
    }
    useEffect(()=>{
       setInvestmentModal( props.investmentModal)
        console.log('props.investmentModal')
        console.log(props.investmentModal)
    },[props.investmentModal])
    return (
<>
    <div className={'teer-all-result-modal'} >
        <Modal
            size="lg"
            centered
            show={investmentModal}
            onHide={handleInvestmentModal}
            className={'custom-modal-content'}
        >
            <Modal.Body >
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <div className="all-invest-now-section-main" id={'all_invest_show'}>
                            <Modal.Header>
                                <h6 className={'teer-investment-title'}>Teer All Investment</h6>
                                <span className={'modal-close-btn-color'} onClick={handleInvestmentModal} ><i className="fas fa-times"/></span>
                            </Modal.Header>
                            {/*Desktop view Design Start*/}
                            <div style={{display:'none'}} className="row">
                                <div className="col-md-12">
                                    <div className="scroll-section">
                                        {/*Twenty Five Teer Section Start*/}
                                        <div className="twenty-five-section-inner">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 25 Number
                                                Results</h6>
                                            {round.map((twentyFive, twentyFiveIndex) => (
                                                <div className="twenty-five-section-inner-data" key={twentyFiveIndex}>
                                                    <div className="row align-items-center">
                                                        <div className="col-4">
                                                            <p>{twentyFive.roundTitle}</p>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <p>{twentyFive.time}</p>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <div className="invest-and-result-count">
                                                                <div className="count-number">
                                                                    {counter ?
                                                                        <>
                                                                            <Countdown
                                                                                date={Date.now() + 9547845687}
                                                                                renderer={renderer_white}
                                                                            />
                                                                            <Link to={'/invest'} className="all-invest-now">Invest
                                                                                Now</Link>
                                                                        </>
                                                                        : null}
                                                                    {counterEnd ?
                                                                        <div className="final-result-new">
                                                                            <p><span className={'winner-icon-new'}><i
                                                                                className="fas fa-trophy"/></span>{twentyFive.winingNumber}
                                                                            </p>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/*Twenty Five Teer Section End*/}

                                        {/*Hundred Five Teer Section Start*/}
                                        <div className="hundred-section-inner mt-2">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 100 Number
                                                Results</h6>
                                            {hundred.map((hundredResult,hundredResultIndex)=>(
                                                <div className="hundred-section-inner-data" key={hundredResultIndex}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6 className={'hundred-teer-single-title'}>{hundredResult.title}</h6>
                                                            {hundredResult.results.map((item,index)=>(
                                                                <div className="single-data" key={index}>
                                                                    <div className="row align-items-center" >
                                                                        <div className="col-4">
                                                                            <p>{item.name}</p>
                                                                        </div>
                                                                        <div className="col-4 text-center">
                                                                            <p>{item.time}</p>
                                                                        </div>
                                                                        <div className="col-4 text-end">
                                                                            <div className="invest-and-result-count">
                                                                                <div className="count-number">
                                                                                    {counter ?
                                                                                        <>
                                                                                            <Countdown
                                                                                                date={Date.now() + 9547845687}
                                                                                                renderer={renderer_white}
                                                                                            />
                                                                                            <Link to={'/invest'} className="all-invest-now">Invest
                                                                                                Now</Link>
                                                                                        </>
                                                                                        : null}
                                                                                    {counterEnd ?
                                                                                        <div className="final-result-new">
                                                                                            <p><span className={'winner-icon-new'}><i
                                                                                                className="fas fa-trophy"/></span>{item.winNumber}
                                                                                            </p>
                                                                                        </div> : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                        {/*Hundred Five Teer Section End*/}

                                        {/*Ninety None Hundred Five Teer Section Start*/}
                                        <div className="ninety-nine-hundred-section-inner mt-2">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 999 Number
                                                Results</h6>
                                            {ninetyNineHundred.map((threeNineResult, threeNineResultIndex) => (
                                                <div className="twenty-five-section-inner-data" key={threeNineResultIndex}>
                                                    <div className="row align-items-center" >
                                                        <div className="col-4">
                                                            <p>{threeNineResult.roundTitle}</p>
                                                        </div>
                                                        <div className="col-4 text-center">
                                                            <p>{threeNineResult.time}</p>
                                                        </div>
                                                        <div className="col-4 text-end">
                                                            <div className="invest-and-result-count">
                                                                <div className="count-number">
                                                                    {counter ?
                                                                        <>
                                                                            <Countdown
                                                                                date={Date.now() + 9547845687}
                                                                                renderer={renderer_white}
                                                                            />
                                                                            <Link to={'/invest'} className="all-invest-now">Invest
                                                                                Now</Link>
                                                                        </>
                                                                        : null}
                                                                    {counterEnd ?
                                                                        <div className="final-result-new">
                                                                            <p><span className={'winner-icon-new'}><i
                                                                                className="fas fa-trophy"/></span>{threeNineResult.winingNumber}
                                                                            </p>
                                                                        </div> : null
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/*Ninety None Hundred Teer Section End*/}
                                    </div>
                                </div>
                            </div>
                            {/*Desktop view Design End*/}


                            {/*Mobile view Design Start*/}
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="scroll-section">
                                        {/*Twenty Five Teer Section Start*/}
                                        <div className="twenty-five-section-inner">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 25 Number
                                                Results</h6>
                                            {round.map((twentyFive, twentyFiveIndex) => (
                                                <div className="twenty-five-section-inner-data" key={twentyFiveIndex}>
                                                    <div className="row align-items-center">
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <p className={'round-title-color'}>{twentyFive.roundTitle}</p>
                                                                </div>
                                                                <div className="col-12">
                                                                    <p>{twentyFive.time}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="row">
                                                                <div className="col-12 text-end">
                                                                    <div className="invest-and-result-count">
                                                                        <div className="count-number">
                                                                            {counter ?
                                                                                    <div className="counter-flex">
                                                                                        <Countdown
                                                                                            date={Date.now() + 9547845687}
                                                                                            renderer={renderer_white}
                                                                                        />
                                                                                        <Link to={'/invest'} className="all-invest-now">Invest
                                                                                            Now</Link>
                                                                                    </div>
                                                                                : null}
                                                                            {counterEnd ?
                                                                                <div className="final-result-new">
                                                                                    <p>
                                                                                        <span className={'winner-icon-new'}>
                                                                                        <i className="fas fa-trophy"/>
                                                                                    </span>
                                                                                    </p>
                                                                                        <p className={'ps-1'}>{twentyFive.winingNumber}</p>

                                                                                </div> : null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/*Twenty Five Teer Section End*/}

                                        {/*Hundred Five Teer Section Start*/}
                                        <div className="hundred-section-inner mt-2">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 100 Number
                                                Results</h6>
                                            {hundred.map((hundredResult,hundredResultIndex)=>(
                                                <div className="hundred-section-inner-data" key={hundredResultIndex}>
                                                    <div className="row">
                                                        <div className="col-12">
                                                            <h6 className={'hundred-teer-single-title'}>{hundredResult.title}</h6>
                                                            {hundredResult.results.map((item,index)=>(
                                                                <div className="single-data" key={index}>
                                                                    <div className="row align-items-center" >
                                                                            <div className="col-6">
                                                                                <div className="col-12">
                                                                                    <p className={'round-title-color'}>{item.name}</p>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <p>{item.time}</p>
                                                                                </div>
                                                                            </div>

                                                                                <div className="col-6">
                                                                                    <div className="row">
                                                                                        <div className="col-12 text-end">
                                                                                            <div className="invest-and-result-count">
                                                                                                <div className="count-number">
                                                                                                    {counter ?
                                                                                                            <div className="counter-flex">
                                                                                                            <Countdown
                                                                                                                date={Date.now() + 9547845687}
                                                                                                                renderer={renderer_white}
                                                                                                            />
                                                                                                            <Link to={'/invest'} className="all-invest-now">Invest
                                                                                                                Now</Link>
                                                                                                            </div>
                                                                                                        : null}
                                                                                                    {counterEnd ?
                                                                                                        <div className="final-result-new">
                                                                                                            <p>
                                                                                                                <span className={'winner-icon-new'}>
                                                                                                                    <i className="fas fa-trophy"/>
                                                                                                                </span>
                                                                                                            </p>
                                                                                                            <p className={'ps-1'}>{item.winNumber}</p>
                                                                                                        </div> : null
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}


                                        </div>
                                        {/*Hundred Five Teer Section End*/}

                                        {/*Ninety None Hundred Five Teer Section Start*/}
                                        <div className="ninety-nine-hundred-section-inner mt-2">
                                            <h6 className={'text-center all-twenty-five-title'}>Shillong Teer 999 Number
                                                Results</h6>
                                            {ninetyNineHundred.map((threeNineResult, threeNineResultIndex) => (
                                                <div className="twenty-five-section-inner-data">
                                                    <div className="row align-items-center" key={threeNineResultIndex}>
                                                        <div className="col-6">
                                                            <div className="col-12">
                                                                <p className={'round-title-color'}>{threeNineResult.roundTitle}</p>
                                                            </div>
                                                            <div className="col-12">
                                                                <p>{threeNineResult.time}</p>
                                                            </div>
                                                        </div>
                                                       <div className="col-6">
                                                           <div className="col-12 text-end">
                                                               <div className="invest-and-result-count">
                                                                   <div className="count-number">
                                                                       {counter ?
                                                                           <div className="counter-flex">
                                                                               <Countdown
                                                                                   date={Date.now() + 9547845687}
                                                                                   renderer={renderer_white}
                                                                               />
                                                                               <Link to={'/invest'} className="all-invest-now">Invest
                                                                                   Now</Link>
                                                                           </div>
                                                                           : null}
                                                                       {counterEnd ?
                                                                           <div className="final-result-new">
                                                                               <p><span className={'winner-icon-new'}>
                                                                                   <i className="fas fa-trophy"/>
                                                                               </span>
                                                                               </p>
                                                                               <p className={'ps-1'}>{threeNineResult.winingNumber}</p>
                                                                           </div> : null
                                                                       }
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/*Ninety None Hundred Teer Section End*/}
                                    </div>
                                </div>
                            </div>
                            {/*Mobile view Design Start*/}
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </div>
</>
    );
};

export default TeerAllResult;
