import React from 'react';

import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import NinetyNineHundredResultRound from "./Include/NinetyNineHundredResultRound";
import {Helmet} from "react-helmet";


const NinetyNineHundredResult = () => {
    return (
        <div>
            <Helmet>
                <title>Result 900 | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <NinetyNineHundredResultRound/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default NinetyNineHundredResult;
