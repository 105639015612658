import React from 'react';
import {Link} from "react-router-dom";
import image2 from "../../Assets/images/teer-img/teer.png";


const InvestmentHundred = () => {
    return (
        <div className={'container'}>
            <Link to={'/hundred'}>
            <div className={'twenty-five-section-main'}>
                <div className="row align-items-center">
                    <div className="col-md-6 col-6">
                            <ul className={'twenty-five-teer-inner'}>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>Shillong Teer Result</li>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>Khanapara Teer Result</li>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>Night Teer Result</li>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>Night Teer 2 Result</li>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>Bhutan Teer Result</li>
                                <li><span className={'twenty-five-teer-inner-icon'}><i className="fas fa-long-arrow-alt-right"/></span>ALL Teer Result</li>
                            </ul>
                    </div>
                    <div className="col-md-6 col-6 text-center">
                        <img className={'teer-invest-image'} src={image2} alt=""/>
                    </div>
                </div>
            </div>
        </Link>
        </div>
    );
};

export default InvestmentHundred;
