import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import MyAccountTopBar from "../MyAccount/Includes/MyAccountTopBar";
import MyAccountAddFund from "../MyAccount/Includes/MyAccountAddFund";
import plogo from "../../../Assets/images/payment-logo/paytm.png";
import glogo from "../../../Assets/images/payment-logo/g-pay.png";
import {Helmet} from "react-helmet";
import {useHistory, useParams} from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";

const Gpay = () => {

    const history = useHistory()
    const  params = useParams()

    useEffect(() => {
        console.log(params)
        setInput(prevState => ({...prevState, payment_method_id : 5, amount : params.amount}))
        getPaymentMethodNumber()
    }, []);


    const [input, setInput] = useState({})
    const [userDetails, setUserDetails] = useState([])
    const getUserProfileInfo = () => {
        axios.get('/api/get-user-details').then(res=>{
            setUserDetails(res.data.data)
        })
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name] : e.target.value}))
    }

    const submitPayment = () => {
        $('#ref_id_input').val('')
        $('#account_no_input').val('')
        axios.post('/api/user-add-fund', input).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 2000
            })
            history.push('/transaction-details/'+res.data.data.id)
        })

    }


    const [bkash_number, set_bkash_number] = useState([])

    const getPaymentMethodNumber = () =>{
        axios.get('/api/get-payment-method-number/5').then(res=>{
            set_bkash_number(res.data)
        })
    }



    return (
        <div>
            <Helmet>
                <title>Rocket Add Fund | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <MyAccountTopBar  title={'Add Fund'}/>
                            <MyAccountAddFund getUserProfileInfo={getUserProfileInfo} userDetails={userDetails}/>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="add-fund-section-main">
                                        <div className="row justify-content-center">
                                            <div className="col-md-6">
                                                <div className="payment-method-inner-section">
                                                    <div className="paytm-payment-logo-img">
                                                        <img className={'img-fluid rocket-payment-logo-updated'} src={glogo} alt=""/>
                                                    </div>
                                                    <div className="payment-instruction">
                                                        <p>Account:  {bkash_number.account_no}</p>
                                                        <p>Amount: {params.amount}</p>
                                                        <p>Reference: {userDetails.account_id}</p>
                                                    </div>
                                                    <div className="transaction-id">
                                                        <p>Submit TrxID Below</p>
                                                        <input
                                                            className={'trx-id-input'}
                                                            type="text"
                                                            placeholder={'eg. 8P95TRRFX'}
                                                            id={'ref_id_input'}
                                                            name={'trx_id'}
                                                            value={input.trx_id}
                                                            onChange={handleInput}
                                                        />
                                                        <p>Sender Number</p>
                                                        <input
                                                            className={'trx-id-input'}
                                                            type="number"
                                                            placeholder={'eg. 39453421389'}
                                                            id={'account_no_input'}
                                                            name={'account_no'}
                                                            value={input.account_no}
                                                            onChange={handleInput}
                                                        />
                                                    </div>
                                                    <div className="proceed-btn text-center">
                                                        <button onClick={submitPayment} className={'proceed-btn-inner'}>PROCEED</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Gpay;
