import React, {useEffect, useState} from 'react';
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";

const MyAccountEditForm = (props) => {

    const [input, setInput] = useState({})
    const [errors, setErrors] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentMethodInput, setPaymentMethodInput] = useState({})
    const [userWithdrawMethods, setUserWithdrawMethods] = useState([])

    useEffect(()=>{
        setInput(props.input)
        setPaymentMethods(props.paymentMethods)
    }, [props.input, props.paymentMethods])
    useEffect(()=>{
        getUserWithdrawMethods()
    }, [])

    const removePaymentMethods = (e, id) => {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post('/api/remove-withdraw-method/'+id).then(res=>{
                    getUserWithdrawMethods()
                })
            }
        })
    }

    const showMethod = () => {
        $('.add-payment-method').slideDown(100);
    }

    const handleInput = (e) => {
        setInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }
    const handlePaymentMethodInput = (e) => {
        if (e.target.name == 'payment_method_id'){
            if (e.target.value != 0){
                $('#payment_method_input_'+e.target.value).slideDown(10).siblings('.add-payment-method').slideUp(10)
            }else{
                $('.add-payment-method').slideUp(10)
            }
        }

        setPaymentMethodInput(prevState => ({...prevState, [e.target.name]: e.target.value}))
    }

    const addPaymentMethod = () => {
        axios.post('/api/add-user-payment-method', paymentMethodInput ).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 2000
            })
            getUserWithdrawMethods()

        })
    }

    const getUserWithdrawMethods = () => {
        axios.get('/api/get-user-payment-method' ).then(res=>{
            setUserWithdrawMethods(res.data)
        })
    }

    const updateProfile = () => {
        axios.post('/api/update-user-profile', input ).then(res=>{
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 2000
            })
            setErrors([])

            props.getUserProfileInfo()
        }).catch(errors => {
            if (errors.response.status === 422) {
                setErrors(errors.response.data.errors)
            }
        })
    }

    return (
        <div className={'row'}>
            <div className="col-md-12">
                <div className="edit-form-account">
                    <form>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group account-form-group mb-3">
                                    <label>Name:</label>
                                    <input
                                        type="text"
                                        className="form-control account-form-control"
                                        placeholder="Name"
                                        name={'name'}
                                        value={input.name}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message-profile-update" key={key}>{key == 'name' ? val : ''}</small>
                                    )) : null}
                                </div>
                                <div className="form-group account-form-group mb-3">
                                    <label>Phone Number:</label>
                                    <input
                                        type="text"
                                        className="form-control account-form-control"
                                           placeholder="Phone Number"
                                        name={'phone'}
                                        value={input.phone}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message-profile-update" key={key}>{key == 'phone' ? val : ''}</small>
                                    )) : null}
                                </div>
                                <div className="form-group account-form-group mb-3">
                                    <label>User Name:</label>
                                    <input
                                        type="text"
                                        className="form-control account-form-control"
                                        placeholder="User Name"
                                        name={'username'}
                                        value={input.username}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message-profile-update" key={key}>{key == 'username' ? val : ''}</small>
                                    )) : null}
                                </div>
                                <div className="form-group account-form-group mb-3">
                                    <label>Email Address:</label>
                                    <input
                                        type="email"
                                        className="form-control account-form-control"
                                           placeholder="Email"
                                        name={'email'}
                                        value={input.email}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ? Object.entries(errors).map(([key, val]) => (
                                        <small className="text-danger validation-error-message-profile-update" key={key}>{key == 'email' ? val : ''}</small>
                                    )) : null}
                                </div>


                            </div>
                            <div className="col-md-6">

                                <div className="text-control withdrew-method-section text-center">
                                    <h5 className={'withdrew-heading'}>Withdrew Method</h5>
                                    <div className="withdrew-flex">
                                        <select onChange={handlePaymentMethodInput} value={paymentMethodInput.payment_method_id} name={'payment_method_id'} className="form-select withdrew-select">
                                            <option value={0}  selected>Select</option>
                                            {paymentMethods.map((method, index)=>(
                                                <option key={index} value={method.id}>{method.name}</option>
                                            ))}
                                        </select>
                                        {/*<button type={'button'} className={'add-method'} onClick={showMethod}>Add</button>*/}
                                    </div>
                                    {paymentMethods.map((method, index)=>(
                                    <div key={index} id={`payment_method_input_${method.id}`} className="add-payment-method" style={{display: 'none'}}>
                                        <label className="">{method.name}:</label>
                                        <input
                                            className={'payment-control'}
                                            type="text"
                                            name={`account_no`}
                                            onChange={handlePaymentMethodInput}
                                        />
                                        <span onClick={addPaymentMethod} className={'minus-account'} dangerouslySetInnerHTML={{__html: 'Add'}}/>
                                    </div>
                                    ))}
                                </div>

                                {userWithdrawMethods.map((method, index)=>(
                                    <div key={index} id={`payment_method_input_${method.id}`} className="add-payment-method mb-3">
                                        <span className="">{method.payment_method.name}:</span>
                                        <span className="">{method.account_no}</span>
                                        <span onClick={(e)=>removePaymentMethods(e, method.id)} className={'minus-account'} dangerouslySetInnerHTML={{__html: '-'}}/>
                                    </div>
                                ))}


                                <div className="form-group account-form-group mb-3">
                                    <label>Country:</label>
                                    {/*<input type="email" className="form-control account-form-control"*/}
                                    {/*       placeholder="Bangladesh" readOnly/>*/}
                                    <select
                                        className="form-select account-form-control"
                                        value={input.country_id}
                                        name={'country_id'}
                                        onChange={handleInput}
                                        disabled={true}
                                    >
                                        <option selected value={'1'}>Bangladesh</option>
                                        <option value={'2'}>India</option>
                                        <option value={'3'}>Bhutan</option>
                                        <option value={'4'}>Nepal</option>
                                        <option value={'5'}>Singapore</option>
                                    </select>
                                </div>

                                <div className="form-group mb-3">
                                    <label>Address:</label>
                                    <textarea
                                        className="form-control "
                                        rows="2"
                                        name={'address'}
                                        value={input.address}
                                        onChange={handleInput}
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 text-center">
                                <div className="account-save-btn text-center">
                                    <button onClick={updateProfile} type={'button'} className={'account-info-save'}>Save Change</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default MyAccountEditForm;
