import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "./Include/UserProfileTopBar";
import UserProfileSideBar from "./Include/UserProfileSideBar";
import MyAccountTopBar from "../MyAccount/Includes/MyAccountTopBar";
import axios from "axios";
import {Link} from "react-router-dom";
import bar from "../../../Assets/images/spinner/bar-white.svg";
import loader from "../../../Assets/images/spinner/ripple.svg";
import Countdown from "react-countdown";
import {Helmet} from "react-helmet";


const Dashboard = () => {

    const [transactions, setTransations] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getTransactionData = () => {
        setIsLoading(true)
        axios.get('/api/get-transaction-data').then(res => {
            setTransations(res.data.data)
            setIsLoading(false)
            // localStorage.setItem('transactions', JSON.stringify(res.data.data))
        });
    }

    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return 'Event Finished';
        } else {
            // Render a countdown
            return <span className={'count-down-main'}>
             <span className={'count-min'}>{days}</span>:
                  <span className={'count-min'}>{hours}</span>:
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };

    useEffect(() => {
        getTransactionData()
        // if (localStorage.transactions != undefined){
        //     setTransations(JSON.parse(localStorage.transactions))
        // }
    }, [])


    return (
        <div>
            <Helmet>
                <title>Dashboard | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'} id={'profile_container'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class" id={'profile_sidebar'}>
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <MyAccountTopBar title={'Dashboard'}/>
                            <div className="row">
                                {/* <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Total Deposit</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.deposit_amount != undefined ? localStorage.deposit_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Total Withdrawal</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.withdrawal_amount != undefined ? localStorage.withdrawal_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Total Win</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.win_amount != undefined ? localStorage.win_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div> */}

                                <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Running Bet</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.running_bet_amount != undefined ? localStorage.running_bet_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Today Loss</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.today_loss_amount != undefined ? localStorage.today_loss_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="dashboard-card">
                                        <h5>Today Win</h5>
                                        <hr className={'dashboard-hr'}/>
                                        <h2>{localStorage.today_win_amount != undefined ? localStorage.today_win_amount : 0}<span className={'span'}>{localStorage.currency}</span></h2>
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="dashboard-card dashboard-trx-card position-relative">
                                        {isLoading ?
                                            <div className="investment-page-loader">
                                                <img src={loader} alt={'loader'}/>
                                            </div> : null
                                        }
                                        <div className="d-flex align-items-center justify-content-between">
                                            <h5>Recent Transaction</h5>
                                            <Link to={'/transaction'}><i className={'fas fa-eye'}/> View More</Link>
                                        </div>

                                        <hr className={'dashboard-hr'}/>
                                        <div className="table-responsive">
                                            <table className="table table-borderless custom-table ">
                                                <thead className={'ticket-table-heading'}>
                                                <tr>
                                                    <th className={'d-none d-md-table-cell'}>Time</th>
                                                    <th className={'d-none d-md-table-cell'}>Type</th>
                                                    <th>Method</th>
                                                    <th className={'d-none d-md-table-cell'}>Transaction ID</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                                </thead>
                                                <tbody className={'ticket-table-body'}>
                                                {transactions.slice(0,10).map((transaction, index) => (

                                                    <tr key={index}>
                                                        <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.time}</Link></td>
                                                        <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.type}</Link></td>
                                                        <td className={'align-middle'}>
                                                            <Link to={`/transaction-details/${transaction.id}`}>
                                                                <small>
                                                                    <p>{transaction.category}</p>
                                                                    <p>{transaction.round} {transaction.frsr != null ? <span class="order-table-frsr-pill ">{transaction.frsr}</span> : null}</p>
                                                                    <p>{transaction.investment_type} {transaction.number != null ? <span class="order-table-number-pill" style={transaction.number == 'Green' ? {backgroundColor: 'green', color: 'white'} : transaction.number == 'Red' ? {backgroundColor: 'red', color: 'white'} : transaction.number == 'Yellow' ? {backgroundColor: 'yellow', color: 'black'} : null}>{transaction.number}</span> : null}</p>
                                                                </small>
                                                                {transaction.method}
                                                            </Link>
                                                        </td>
                                                    
                                                        <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.transaction_id}</Link></td>
                                                        <td className={'align-middle Win'}>
                                                            <Link to={`/transaction-details/${transaction.id}`}>
                                                                {transaction.amount} {localStorage.currency}
                                                                {transaction.win_amount != null ?
                                                                    <span className={'win-amount-in-table'}>  <i className="fas fa-trophy"/> {transaction.win_amount}</span> : null
                                                                }
                                                            </Link>
                                                        </td>
                                                        <td className={'loader-relative align-middle'}>
                                                            <Link to={`/transaction-details/${transaction.id}`}>
                                                                <div className={`status-name ${transaction.status}`}>
                                                                    <div>
                                                                        {transaction.status == 'Win' ? <span><i className="fas fa-trophy"/> </span> : null}
                                                                        {transaction.status == 'Loss' ? <span><i className="fas fa-heart-broken"/> </span> : null}
                                                                        {transaction.status == 'Running' ? <span><i className="fas fa-running"/> </span> : null}
                                                                        <p>{transaction.status}</p>                                                                        
                                                                        </div>

                                                                    {transaction.status == 'Running' ?
                                                                        <div className="status-icon">
                                                                            <div className="count-down">
                                                                                <Countdown
                                                                                    date={transaction.expire ? Date.now() : transaction.raw_time}
                                                                                    renderer={renderer}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        : null

                                                                    }

                                                                </div>
                                                            </Link>
                                                        </td>
                                                    </tr>

                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
