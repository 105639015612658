import React, {useState} from 'react';
import {Link} from "react-router-dom";

const LeftSideBar = () => {
    const [menu]=useState([
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
        {icon:'fas fa-long-arrow-alt-right', name:'Add captions to your slides', url:'/'},
    ])
    return (
        <div className={'left-side-bar'}>
            <h6 className={'text-center side-bar-border-bottom'}>Games</h6>
            <div className="left-side-bar-content">
                {/*{menu.map((item,index)=>(*/}
                {/*    <Link to={item.url} className={'left-side-bar-menu'} key={index}><span className={'side-bar-content-icon'}><i className={item.icon}/></span>{item.name}</Link>*/}
                {/*))}*/}
                <h5 className={'text-center'}>
                <li className="games_nav_item">
                                <Link className="nav-link hover-effect custom-nav-color" to="/twenty-five">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Investment (25)
                                </Link>
                                <Link className="nav-link hover-effect custom-nav-color" to="/hundred">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Investment (100)
                                </Link>
                                <Link className="nav-link hover-effect custom-nav-color" to="/nine-hundred">
                                    <span/>
                                    <span/>
                                    <span/>
                                    <span/>
                                    Investment (900)
                                </Link>
                            </li>
                </h5>
            </div>

        </div>
    );
};

export default LeftSideBar;
