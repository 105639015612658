import React, {useState} from 'react';
import {Link} from "react-router-dom";

const TopBar = () => {
    const [socialIcon]=useState([
        {icon: 'fab fa-facebook', url : 'https://facebook.com'},
        {icon: 'fab fa-whatsapp', url : 'https://wa.me/+916009877616'},
        {icon: 'fab fa-whatsapp', url : 'https://wa.me/+8801703945133'},
        {icon: 'fab fa-twitter', url : 'https://twitter.com'},
        {icon: 'fab fa-instagram', url : 'https://instagram.com'},
        {icon: 'fab fa-google', url : 'https://google.com'},
    ])
    return (
            <div className="top-bar">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-md-4">
                            <div className="top-bar-section-inner text-center text-md-start">
                            <a href={'mailto:teer125@gmail.com'}
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fas fa-envelope"/></span>
                                </a>                                
                                <span className={'mx-2'}>|</span>
                            <a href={'https://wa.me/+916009877616'} target="_blank"
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fab fa-whatsapp"/></span>
                                    +916009877616
                                </a>
                                <span className={'mx-2'}>|</span>
                                <a href={'https://wa.me/+8801703945133'} target="_blank"
                                   className={'top-bar-font-color'}>
                                    <span className={'top-bar-mail-icon'}><i className="fab fa-whatsapp"/></span>
                                    +8801703945133
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="top-bar-section-inner text-center">
                                <p/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="top-bar-section-inner text-center text-md-end">
                                {socialIcon.map((item,index)=>(
                                    <a class="top-bar-social-icon-color" target="_blank" href={item.url}><i class={item.icon}></i></a>
                                    // <Link className={'top-bar-social-icon-color'} to={item.url} target={'_blank'} key={index}>
                                    //     <i className={item.icon}/>
                                    // </Link>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
};

export default TopBar;
