import React, {useEffect} from 'react';
import {BrowserRouter, Route} from 'react-router-dom'
import './App.css';
import '../node_modules/@fortawesome/fontawesome-free/js/fontawesome.min'
import '../node_modules/@fortawesome/fontawesome-free/js/brands.min'
import '../node_modules/@fortawesome/fontawesome-free/js/solid.min'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import './Assets/css/style.css'
import './Assets/css/media.css'
import Home from "./Components/Pages/Home";
import Login from "./Components/Pages/Login";
import TwentyFiveResult from "./Components/Pages/TwentyFiveResult/TwentyFiveResult";
import InvestmentMain from "./Components/Pages/UserDashboard/InvestmentMain";
import HundredResult from "./Components/Pages/HundredResult/HundredResult";
import PreviousHistory from "./Components/Pages/PreviousHistory/PreviousHistory";
import OpenTicket from "./Components/Pages/SupportTicket/OpenTicket";
import MyTicket from "./Components/Pages/SupportTicket/MyTicket";
import ViewTickets from "./Components/Pages/SupportTicket/ViewTickets";
import TransactionList from "./Components/Pages/TansactionList/TransactionList";
import MyAccount from "./Components/Pages/MyAccount/MyAccount";
import Withdrew from "./Components/Pages/MyAccount/Includes/Withdraw";
import AddFund from "./Components/Pages/MyAccount/Includes/AddFund";
import TransactionDetails from "./Components/Pages/TansactionList/Include/TransactionDetails";
import BkashPersonal from "./Components/Pages/PaymentGeteway/BkashPersonal";
import NagadPersonal from "./Components/Pages/PaymentGeteway/NagadPersonal";
import RocketPersonal from "./Components/Pages/PaymentGeteway/RocketPersonal";
import About from "./Components/Pages/About/About";
import TermsAndConditions from "./Components/Pages/Others/TermsAndConditions";
import PrivacyPolicy from "./Components/Pages/Others/PrivacyPolicy";
import NinetyNineHundredResult from "./Components/Pages/NinetyNineHundredResult/NinetyNineHundredResult";
import ScrollToTop from "./Components/ScrollToTop";
import ChangePassword from "./Components/Pages/MyAccount/Includes/ChangePassword";
import UserAuth from "./RouteGaurd/UserAuth";
import axios from "axios";
import Dashboard from "./Components/Pages/UserDashboard/Dashboard";
import Paytm from "./Components/Pages/PaymentGeteway/Paytm";
import Gpay from "./Components/Pages/PaymentGeteway/Gpay";
import ForgetPassword from "./Components/Pages/ForgetPassword";

function App() {

        useEffect(()=>{
                let url = window.location.pathname
                if (url != '/login' && url != '/' && url != '/about' && url != '/reset-password') {
                        axios.post('/api/check-user-auth').then(res => {
                                if (res.data.msg == true) {

                                } else {
                                        localStorage.removeItem('user_token')
                                        localStorage.removeItem('name')
                                        localStorage.removeItem('email')
                                }
                        }).catch(errors => {
                                if (errors.response.status === 401) {
                                        localStorage.removeItem('user_token')
                                        localStorage.removeItem('name')
                                        localStorage.removeItem('email')
                                        window.location.reload()
                                }
                        })
                }
        }, [])


    return (
        <BrowserRouter>
            <ScrollToTop/>
            <Route exact path="/" component={Home}/>
            <Route path="/login" component={Login}/>
            <Route path="/reset-password" component={ForgetPassword}/>
            <Route path="/about" component={About}/>
            <Route path="/terms-and-condition" component={TermsAndConditions}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>

        
            <UserAuth path="/twenty-five" component={TwentyFiveResult}/>            
            <UserAuth path="/previous-result" component={PreviousHistory}/>
            <UserAuth path="/hundred" component={HundredResult}/>
            <UserAuth path="/nine-hundred" component={NinetyNineHundredResult}/>
            <UserAuth path="/invest/:slug/:round_id/:frsr_id?" component={InvestmentMain}/>
            <UserAuth path="/my-ticket" component={MyTicket}/>
            <UserAuth path="/view-ticket" component={ViewTickets}/>
            <UserAuth path="/transaction" component={TransactionList}/>
            <UserAuth path="/my-account" component={MyAccount}/>
            <UserAuth path="/dashboard" component={Dashboard}/>
            <UserAuth path="/payment-withdrew" component={Withdrew}/>
            <UserAuth path="/add-fund" component={AddFund}/>
            <UserAuth path="/transaction-details/:id" component={TransactionDetails}/>
            <UserAuth path="/bkash-payment/:amount" component={BkashPersonal}/>
            <UserAuth path="/nagad-payment/:amount" component={NagadPersonal}/>
            <UserAuth path="/rocket-payment/:amount" component={RocketPersonal}/>
            <UserAuth path="/paytm-payment/:amount" component={Paytm}/>
            <UserAuth path="/gpay-payment/:amount" component={Gpay}/>
            {/* <UserAuth path="/change-password" component={ChangePassword}/> */}

        </BrowserRouter>
    );
}

export default App;
