import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import loader from './../../../../Assets/images/spinner/ripple.svg'
import $ from 'jquery'
import Countdown from "react-countdown";
import Pagination from "react-js-pagination";

const TableTransactionList = () => {

    const [transactions, setTransations] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [itemsCountPerPage, setItemsCountPerPage] = useState(20)
    const [totalItemsCount, setTotalItemsCount] = useState(0)
    const [activePage, setActivePage] = useState(1)

    useEffect(() => {
        getTransactionData()
        // if (localStorage.transactions != undefined) {
        //     setTransations(JSON.parse(localStorage.transactions))
        // }
    }, []);


    const getTransactionData = (pageNumber = 1) => {
        setIsLoading(true)
        axios.get('/api/get-transaction-data?page=' + pageNumber + '&status=' + transaction_status + '&per_page=' + per_page + '&type=' + transaction_type).then(res => {
            setTransations(res.data.data)
            setItemsCountPerPage(parseInt(res.data.meta.per_page))
            setTotalItemsCount(res.data.meta.total)
            setActivePage(res.data.meta.current_page)

            // setAppointment(res.data.data)
            // setItemsCountPerPage()
            // setTotalItemsCount(res.data.meta.total)
            // setActivePage(res.data.meta.current_page)


            setIsLoading(false)

            // localStorage.setItem('transactions', JSON.stringify(res.data.data))
        });
    }


    const renderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return 'Event Finished';
        } else {
            // Render a countdown
            return <span className={'count-down-main'}>
             <span className={'count-min'}>{days}</span>:
                  <span className={'count-min'}>{hours}</span>:
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };
    const [transaction_status, setTransactionStatus] = useState(0)
    const handleTransactionStatus = (e, status) => {
        $(`#status_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransactionStatus(status)
    }


    const [transaction_type, setTransaction_type] = useState(0)
    const handleTransactionType = (e, status) => {
        $(`#type_button_${status}`).addClass('transaction-btn-active').siblings('.transaction-btn').removeClass('transaction-btn-active')
        setTransaction_type(status)
    }


    const [per_page, set_per_page] = useState(20)

    const handlePerPage = (e) => {
        set_per_page(e.target.value)
    }

    // useEffect(()=>{
    //     getTransactionData()
    // }, [per_page])


    useEffect(() => {
        getTransactionData()
    }, [transaction_status, transaction_type])


    return (
        <div className={'position-relative'}>
            {isLoading ?
                <div className="investment-page-loader">
                    <img src={loader} alt={'loader'}/>
                </div> : null
            }
            <div className="all-transaction-btn">
                <div className="row align-items-center">
                    <div className="col-lg-12 text-center">
                        <h4 className={'transaction-title'}>Transaction List</h4>
                    </div>
                    <div className="col-lg-12 mt-2">
                        <div className="button-transaction text-center">
                            <button onClick={(e) => handleTransactionType(e, '0')} id={'type_button_0'} className={'transaction-btn transaction-btn-active'} type={'button'}>All</button>
                            <button onClick={(e) => handleTransactionType(e, '1')} id={'type_button_1'} className={'transaction-btn'} type={'button'}>Bet</button>
                            <button onClick={(e) => handleTransactionType(e, '2')} id={'type_button_2'} className={'transaction-btn'} type={'button'}>Add Fund</button>
                            <button onClick={(e) => handleTransactionType(e, '3')} id={'type_button_3'} className={'transaction-btn'} type={'button'}>Withdraw</button>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-2">
                        <div className="button-transaction text-center">
                            <button onClick={(e) => handleTransactionStatus(e, '0')} id={'status_button_0'} className={'transaction-btn transaction-btn-active'} type={'button'}>All</button>
                            {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '1')} id={'status_button_1'} className={'transaction-btn'} type={'button'}>Running</button> : null}
                            {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '2')} id={'status_button_2'} className={'transaction-btn'} type={'button'}>Win</button> : null}
                            {transaction_type == 1 || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '3')} id={'status_button_3'} className={'transaction-btn'} type={'button'}>Loss</button> : null}
                            {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '4')} id={'status_button_4'} className={'transaction-btn'} type={'button'}>Pending</button> : null}
                            {transaction_type == 2 || transaction_type == 3  || transaction_type == 0 ? <button onClick={(e) => handleTransactionStatus(e, '5')} id={'status_button_5'} className={'transaction-btn'} type={'button'}>Approved</button> : null}
                        </div>
                    </div>
                </div>
            </div>


            <div className="all-transaction-table">
                <div className="show-ticket">
                    <div className="main-all-order-section">
                        <label className={'all-order-section-label'}>Show:</label>
                        <select name={'per_page'} value={per_page} onChange={handlePerPage} className={'form-select all-order-select-section'}>
                            <option value={10} className={'all-order-option-section'}> 10 entries</option>
                            <option value={20} className={'all-order-option-section'}> 20 entries</option>
                            <option value={50} className={'all-order-option-section'}>50 entries</option>
                            <option value={100} className={'all-order-option-section'}>100 entries</option>
                            <option value={200} className={'all-order-option-section'}>200 entries</option>
                        </select>
                    </div>
                    {/* <div className="transaction-list-search-box">
                        <input className="transaction-list-search-box-main" type="text" placeholder="Search"/>
                        <span className={'search-icon-absolute'}><i className="fas fa-search"/></span>
                    </div> */}
                </div>


                {/*/!*Desktop responsive table start*!/*/}
                <div className="table-responsive">
                    <table className="table table-borderless custom-table ">
                        <thead className={'ticket-table-heading'}>
                        <tr>
                            <th className={'d-none d-md-table-cell'}>Time</th>
                            <th className={'d-none d-md-table-cell'}>Type</th>
                            <th>Method</th>
                            <th className={'d-none d-md-table-cell'}>Transaction ID</th>
                            <th>Amount</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody className={'ticket-table-body'}>
                        {transactions.map((transaction, index) => (

                            <tr key={index}>
                                <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.time}</Link></td>
                                <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.type}</Link></td>
                                <td className={'align-middle'}>
                                    <Link to={`/transaction-details/${transaction.id}`}>
                                        <small>
                                            <p>{transaction.category}</p>
                                            <p>{transaction.round} {transaction.frsr != null ? <span class="order-table-frsr-pill ">{transaction.frsr}</span> : null}</p>
                                            <p>{transaction.investment_type} {transaction.number != null ? <span class="order-table-number-pill" style={transaction.number == 'Green' ? {backgroundColor: 'green', color: 'white'} : transaction.number == 'Red' ? {backgroundColor: 'red', color: 'white'} : transaction.number == 'Yellow' ? {backgroundColor: 'yellow', color: 'black'} : null}>{transaction.number}</span> : null}</p>
                                        </small>
                                        {transaction.method}
                                    </Link>
                                </td>

                                <td className={'align-middle d-none d-md-table-cell'}><Link to={`/transaction-details/${transaction.id}`}>{transaction.transaction_id}</Link></td>
                                <td className={'align-middle Win'}>
                                    <Link to={`/transaction-details/${transaction.id}`}>
                                        {transaction.amount} {localStorage.currency}
                                        {transaction.win_amount != null ?
                                            <span className={'win-amount-in-table'}>  <i className="fas fa-trophy"/> {transaction.win_amount}</span> : null
                                        }
                                    </Link>                                    
                                </td>
                                <td className={'loader-relative align-middle'}>
                                    <Link to={`/transaction-details/${transaction.id}`}>
                                        <div className={`status-name ${transaction.status}`}>
                                            <div>
                                                {transaction.status == 'Win' ? <span><i className="fas fa-trophy"/> </span> : null}
                                                {transaction.status == 'Loss' ? <span><i className="fas fa-heart-broken"/> </span> : null}
                                                {transaction.status == 'Running' ? <span><i className="fas fa-running"/> </span> : null}
                                                {transaction.status}
                                            </div>

                                            {transaction.status == 'Running' ?
                                                <div className="status-icon">
                                                    <div className="count-down">
                                                        <Countdown
                                                            date={transaction.expire ? Date.now() : transaction.raw_time}
                                                            renderer={renderer}
                                                        />
                                                    </div>
                                                </div>
                                                : null

                                            }

                                        </div>
                                    </Link>
                                </td>
                            </tr>

                        ))}
                        </tbody>
                    </table>
                </div>
                {/*/!*Desktop responsive table end*!/*/}

                <div className="all-transaction-page-info">
                    <div className="data-tables-info">
                        <p>Showing {((activePage - 1) * per_page) + 1} to {(activePage * per_page) > totalItemsCount ? totalItemsCount : activePage * per_page} of {totalItemsCount} entries</p>
                    </div>
                    <div className="data-tables-paginate">
                        {totalItemsCount > per_page ?
                            <nav className={'pagination-custom'}>
                                <Pagination
                                    activePage={activePage}
                                    itemsCountPerPage={itemsCountPerPage}
                                    totalItemsCount={totalItemsCount}
                                    // pageRangeDisplayed={5}
                                    onChange={getTransactionData}
                                    nextPageText="Next"
                                    prevPageText="Previous"
                                    itemClassNext="page-item"
                                    itemClassPrev="page-item"
                                    itemClassFirst="page-item"
                                    itemClassLast="page-item"
                                    firstPageText="First"
                                    lastPageText="Last"
                                />
                            </nav> : null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableTransactionList;
