import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import $ from "jquery";
import profilePic from "../../../../Assets/images/clint/demo.jpg";
import axios from "axios";
import Swal from "sweetalert2";
const MyAccountAddFund = (props) => {

    const [userDetails , setUserDetails] = useState([])
    const [profilePhoto, setProfilePhoto] = useState(profilePic)
    useEffect(() => {
        setUserDetails(props.userDetails)
        setProfilePhoto(props.userDetails.photo)
    }, [props.userDetails]);

    const handleErrorImage = (e) => {
        e.target.src = profilePic
    }

    const uploadProfilePhoto = () => {
        $('#profile_img_upload_inside').trigger('click')
    }

    const [photoInput , setPhotoInput] =useState()
    const uploadImage = (e) => {
        let reader = new FileReader()
        let file = e.target.files[0]
        let photoInput = {}
        reader.onloadend = () =>{
            setProfilePhoto(reader.result)
            localStorage.profile_photo = reader.result
            setPhotoInput({photo: reader.result})
        }

        reader.readAsDataURL(file)
    }
    useEffect(()=>{
        axios.post('/api/upload-user-profile-photo', photoInput ).then(res=>{
            props.getUserProfileInfo()
        })
    }, [photoInput])



    // const changeProfilePhoto = () => {
    //     setProfilePhoto(localStorage.profile_photo)
    // }


    useEffect(()=>{
        setProfilePhoto(localStorage.profile_photo != undefined ? localStorage.profile_photo : profilePic)
    }, [])

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="my-account-main-section">
                    <div className="row align-items-center">
                        <div className="col-md-6 account-user-name">
                            <div className="user-name-padding">
                                <p>User Name : {localStorage.user_name}</p>
                                <p>Account ID: {localStorage.account_id}</p>
                            </div>
                            <div className="profile-picture">
                                <img onError={handleErrorImage} src={profilePhoto} alt="profile-picture" onClick={uploadProfilePhoto}/>
                                <input onChange={uploadImage} type={'file'} className={'d-none'} id={'profile_img_upload_inside'}/>
                                {/*<button className={'d-none'} id={'my_account_profile_photo'} onClick={changeProfilePhoto}/>*/}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="acc-add-fund account-balance">
                                <p className={'balance'}>Balance: {localStorage.balance != undefined ? localStorage.balance : 0} <span className={'span'}>{localStorage.currency}</span></p>
                                <div className="withdraw-and-add-fund">
                                    <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'/add-fund'}>Add Fund +</Link></button>
                                    <button className={'add-fund-my-account'}><Link className={'fund-title-color'} to={'/payment-withdrew'}>Withdraw</Link> </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccountAddFund;
