import React from 'react';
import './../../../node_modules/slick-carousel/slick/slick.css'
import './../../../node_modules/slick-carousel/slick/slick-theme.css'
import SliderBanner from "./SlideBanner";
import LeftSideBar from "./LeftSideBar";


const BannerSlider = () => {

    return (
            <div className={'container'}>
                <div className="row mt-3 mb-3">
                    <div className="col-lg-3 col-md-5">
                        <LeftSideBar/>
                    </div>
                    <div className="col-lg-9 col-md-7">
                        <SliderBanner/>
                    </div>
                </div>
            </div>
    );
};

export default BannerSlider;
