import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import Countdown from "react-countdown";
import axios from "axios";
import loader from './../../../../Assets/images/spinner/ripple.svg'

const TwentyFiveResultRound = () => {

    const [category, setCategory] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getCategoryDetails = () => {
        setIsLoading(true)
        axios.get('/api/get-category-details/1').then(res => {
            setCategory(res.data.data)
            setIsLoading(false)
            // setCounterEnd(false)
            localStorage.setItem('category_1' , JSON.stringify(res.data.data))
        })
    }

    useEffect(() => {
        getCategoryDetails()
        if (localStorage.category_1 != undefined){
            setCategory(JSON.parse(localStorage.category_1))
        }

        setInterval(()=>{
            console.log('hello')
            getCategoryDetails()
        }, 10000)

    }, [])

    const [counterEnd, setCounterEnd] = useState(false)

    const [timeOut , setCustomTimeOut] = useState('')
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state

            // getCategoryDetails()
            setCounterEnd(true)
            setCounter(false)



            // const timeoutID = setTimeout(() => {
            //     console.log('hello')
            // }, 1000);
            //
            // clearTimeout(timeoutID);




         //   window.location.reload()

             return null;
        } else {
            // Render a countdown
            return <span className={'hundred-count-down'}>
                  <span className={'count-min'}>{hours}</span>:
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };



    useEffect(() => {
        setCounter(true)

    }, [])

    useEffect(()=>{
        getCategoryDetails()
        console.log(counterEnd)
    }, [counterEnd])


    const [counter, setCounter] = useState(false)

    return (
        <div className="hundred-result-round-main position-relative">
            {isLoading && localStorage.category_1 == undefined ?
                <div className="investment-page-loader">
                    <img src={loader} alt={'loader'}/>
                </div> : null
            }

            <div className="result-title">
                <h3 className={'text-center  result-header'}>Shillong Teer <br/> 25 Number Results </h3>
                <div className="row">
                    {Object.keys(category).length > 0 && category.round != undefined ? category.round.map((item, index) => (
                        <div className="col-sm-6 mb-3" key={index}>
                            <div className="hundred-card">
                                <p className="hundred-card-text">{item.name}</p>
                                <div className="hundred-card-body">
                                    <h5 className="hundred-card-title">{item.time}</h5>
                                    <div className="invest-and-result-count">
                                        <div className="count-number">
                                            <Countdown
                                                date={item.expire ? Date.now() : item.raw_time}
                                                // date={Date.now() + item.raw_time}
                                                renderer={renderer}
                                            />
                                            {!item.expire ?
                                                <Link to={`/invest/${category.slug}/${item.id}`} className="hundred-button invest-button">Bet Now</Link> : null
                                            }


                                            {item.expire ?
                                                <div className="final-result">
                                                    <p><span className={'winner-icon'}><i className="fas fa-trophy"/></span> <span dangerouslySetInnerHTML={{__html: item.result != null ? item.result : '<span class="spinner-border spinner-border-sm"></span>'}}/></p>
                                                </div> : null
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : null}
                </div>
            </div>
        </div>
    );
};

export default TwentyFiveResultRound;
