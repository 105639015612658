import React, {useEffect, useState} from 'react';
import $ from "jquery";
import axios from "axios";
import loader from "../../../../Assets/images/spinner/ripple.svg";

const UserProfileMainContent = (props) => {

    const [category, setCategory] = useState({})
    const [params, setParams] = useState([])
    const [selectedNumber, setSelectedNumber] = useState([])
    const [selectedHouseEnding, setSelectedHouseEnding] = useState([])
    const [selectedColor, setSelectedColor] = useState([])
    useEffect(() => {
        getCartData()
    }, [])

    const getCartData = () => {
        axios.post('/api/get-cart-items').then(res => {
            localStorage.setItem('cart', JSON.stringify( res.data.carts))
            let selected = []
            let selectedHouseEnding = []
            let selectedColor = []
            res.data.carts.map((item, index) => (
                item.round_id == props.params.round_id && item.dom_type == 'number'?
                    selected.push(item.number_dom) :
                    item.frsr_id != null && item.area_id == props.params.round_id && item.frsr_id == props.params.frsr_id ?
                        selected.push(item.number_dom) :null
            ))
            res.data.carts.map((item3, index) => (
                item3.round_id == props.params.round_id && item3.dom_type == 'color'?
                    selectedColor.push(item3.number_dom) :
                    item3.frsr_id != null && item3.area_id == props.params.round_id && item3.frsr_id == props.params.frsr_id ?
                        selectedColor.push(item3.number_dom) :null
            ))
            res.data.carts.map((item2, index) => (
                item2.round_id == props.params.round_id ? selectedHouseEnding.push(item2.number_dom_house) : item2.frsr_id != null && item2.area_id == props.params.round_id && item2.frsr_id == props.params.frsr_id ? selectedHouseEnding.push(item2.number_dom_house) :null
            ))

            setSelectedNumber(selected)
            setSelectedHouseEnding(selectedHouseEnding)
            setSelectedColor(selectedColor)

            console.log('selectedColor')
            console.log(selectedColor)

        })
    }

    useEffect(() => {
        setParams(props.params)
    }, [props.params]);



    useEffect(() => {
        setCategory(props.category)
    }, [props.category]);

    const [number_category, set_number_category] = useState([])
    useEffect(() => {
        set_number_category(props.number_category)
    }, [props.number_category]);


    const clickData = (investment_number_id, type, color_id = null) => {

        console.log(investment_number_id)
        console.log(type)
        let data = {
         //   round_id: params.round_id,
            category_id: params.slug,
        }

        if (type == 'number') {
            $(`#content_data_${investment_number_id}_${params.round_id}`).addClass('content-data-active')
            data = {...data, investment_number_id: investment_number_id}
        } else if (type == 'color') {
            $(`#content_data_color_${investment_number_id}_${params.round_id}`).addClass('teer-button-inner-active')
            data = {...data, investment_color_id: color_id}
        }else if (type == 'house'){
            console.log(`#content_data_house_${investment_number_id}_${params.round_id}`)
            $(`#content_data_house_${investment_number_id}_${params.round_id}`).addClass('content-data-active')
            data = {...data, investment_house_ending_id: investment_number_id}
        }else if (type == 'ending'){
            $(`#content_data_ending_${investment_number_id}_${params.round_id}`).addClass('content-data-active')
            data = {...data, investment_house_ending_id: investment_number_id}
        }

        if (params.frsr_id != undefined){
            data = {...data, frsr_id: params.frsr_id, area_id: params.round_id}
        }else{
            data = {...data, round_id: params.round_id}
        }

        // if ()

        addToCart(data)

        $('#show_data').slideDown(100);
        let count = $('#cart_count').text();
        count = parseInt(count) + 1
        $('#cart_count').text(count)
    }

    const addToCart = (data) => {
        axios.post('/api/add-to-cart', data).then(res => {
            $('#get_cart_data').trigger('click')
        })
    }


    const unselect_selected_numbers = () => {
      $('.teer-button-inner').removeClass('teer-button-inner-active')

      $('.content-data').removeClass('content-data-active')
    }


    const [searchTerm , setSearchTerm] = useState('')

    const handleSearch = (e) => {
      setSearchTerm(e.target.value)
    }

    return (
        <div className={'profile-main-content position-relative'}>
            {props.isLoading && localStorage.getItem(props.params.slug.replace('-', '_')+'_number') == undefined?
                <div className="investment-page-loader">
                    <img src={loader} alt={'loader'}/>
                </div> : null
            }
            <div className="profile-main-content-flex">
                <h6 className={'profile-main-content-header'}>Shillong Teer <i className="fas fa-angle-double-right"/>  {number_category.category } <i className="fas fa-angle-double-right"/> { number_category.round_name} {number_category.frsr != null ? <i className="fas fa-angle-double-right"/> : null }  {number_category.frsr != null ? number_category.frsr:null}  </h6>
                <div className="search-invest">
                    <div className="input-group flex-nowrap">
                        <input
                            type="number"
                            className="form-control search-control"
                            placeholder="Search"
                            onChange={handleSearch}
                        />
                        <span className="input-group-text invest-search-icon"><i className="fas fa-search"/></span>
                    </div>
                </div>
            </div>
            <div className="teer-result-button text-center">
                {Object.keys(category).length > 0 && category.color != undefined ? category.color.map((color, index) => (
                    <button id={'content_data_color_' + color.color+'_'+params.round_id} onClick={(e) => clickData(color.color, 'color', color.id)}
                            className={selectedColor.includes(color.id) ? 'teer-button-inner-active teer-button-inner' : 'teer-button-inner'} type={'button'}
                            style={{background: color.color, color: color.color == 'Yellow'? 'black':'white' }}>{color.color}</button>
                )) : null}
            </div>

            {/*house stats*/}
            {props.params.slug == 'bet-100' ?
            <>
                <div className="profile-main-content-inner  house-area ">
                    <div className="row align-items-center">
                        <div className="col-md-2 mb-3 mb-md-0">
                            <h3>House </h3>
                        </div>
                        <div className="col-md-10">
                            <div className="content-data-flex">
                                {Object.keys(category).length > 0 &&  category.house != undefined ? category.house.map((number, index) => (
                                    <div id={'content_data_house_' + number.id + '_' + params.round_id}
                                         className={selectedHouseEnding.includes(number.id)  ? 'content-data content-data-active' : 'content-data'}
                                         onClick={(e) => clickData(number.id, 'house')} key={index}>
                                        <h6 className={'content-height'}>{number.number}</h6>
                                    </div>
                                )) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="profile-main-content-inner end-area mb-4">
                    <div className="row align-items-center">
                        <div className="col-md-2 mb-3 mb-md-0">
                            <h3>Ending</h3>
                        </div>
                        <div className="col-md-10">
                            <div className="content-data-flex">
                                {Object.keys(category).length > 0 && category.ending != undefined ? category.ending.map((number, index) => (
                                    <div id={'content_data_ending_' + number.id + '_' + params.round_id}
                                         className={selectedHouseEnding.includes(number.id)  ? 'content-data content-data-active' : 'content-data'}
                                         onClick={(e) => clickData(number.id, 'ending')} key={index}>
                                        <h6 className={'content-height'}>{number.number}</h6>
                                    </div>
                                )) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>:null
            }

            {/*house ends*/}

            <div className="profile-main-content-inner">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content-data-flex">
                            {Object.keys(category).length > 0 && category.number != undefined ? category.number.filter((number) => {
                                if (searchTerm == "") {
                                    return number
                                } else if (number.number.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return number
                                }
                            }).map((number, index) => (
                                <div id={'content_data_' + number.id + '_' + params.round_id}
                                     className={selectedNumber.includes(number.id)  ? 'content-data content-data-active' : 'content-data'}
                                     onClick={(e) => clickData(number.id, 'number')} key={index}>
                                    <h6 className={'content-height'}>{number.number}</h6>
                                </div>
                            )) : null}
                        </div>
                    </div>
                </div>
            </div>
            <button className={'d-none'} id={'get_cart_data_25'} onClick={getCartData}/>
            <button className={'d-none'} id={'unselect_selected_numbers'} onClick={unselect_selected_numbers}/>
        </div>
    );
};

export default UserProfileMainContent;
