import React, {useEffect, useState} from 'react';
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
import cartLoader from './../../../../Assets/images/spinner/bars.svg'
import {useParams} from "react-router-dom";

const Cart = (props) => {

    const [carts, setCarts] = useState([])
    const [account, setAccount] = useState([])
    const [cart_id, setCart_id] = useState(null)
    const [order_place_button , set_order_place_button] = useState(false)

    useEffect(() => {
        getCartData()
        if (localStorage.carts) {
            setCarts(JSON.stringify(localStorage.carts))
        }

    }, [])

    const [isOrderLoading, setIsOrderLoading] = useState(false)

    const placeOrder = () => {
        setIsOrderLoading(true)
        axios.post('/api/place-order').then(res => {
            getCartData()
            $('#top_bar_get_profile_data').trigger('click')
            $('#unselect_selected_numbers').trigger('click')
            setIsOrderLoading(false)

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    const allClose = () => {
        $('#details_data_main').hide();
    }
    const showDataMain = () => {
        $('#details_data_main').slideToggle(100);
        setTimeout(() => {
            let display = $('#details_data_main').css('display')
            console.log(display)
            if (display == 'none') {
                $('#cart_data_inner').css('border-top', '2px solid #03e82d')
            } else {
                $('#cart_data_inner').css('border-top', 'none')
            }
        }, 150)

    }


    const params = useParams()


    const removeItemFromCart = (e, id, dom_id, dom_type) => {

        // $( `#bet_cart_items_${id}`).empty()

        if (dom_type == 'number') {
            $(`#content_data_${dom_id}_${params.round_id}`).removeClass('content-data-active')
        } else if (dom_type == 'color') {
            $(`#content_data_color_${dom_id}`).removeClass('teer-button-inner-active')
        } else if (dom_type == 'house_ending') {
            $(`#content_data_house_${dom_id}_${params.round_id}`).removeClass('content-data-active')
        }


        let myArray = carts;

        myArray = myArray.filter(function (obj) {
            return obj.id !== id;
        });

        setCarts(myArray)

        axios.delete('/api/delete-cart-items/' + id).then(res => {
            getCartData()
            $('#get_cart_data_25').trigger('click')
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                toast: true,
                title: res.data.msg,
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    const [cartIsLoading, setCartIsLoading] = useState(false)
    const getCartData = () => {
        setCartIsLoading(true)
        axios.post('/api/get-cart-items').then(res => {
            setCarts(res.data.carts)
            setAccount(res.data.account)
            localStorage.setItem('cart', JSON.stringify(res.data.carts))
            setCartIsLoading(false)
        })
    }

    const handleMinimumInput = (e, id, return_rate) => {
        // e.target.select()
        let value = $(`#cart_amount_input_${id}`).val()
        if (value > 5) {
            $(`#cart_amount_return_${id}`).text(5 * return_rate)
        } else {
            $(`#cart_amount_return_${id}`).text(value * return_rate)
        }

    }
    const handleReturnAmount = (e, id, return_rate) => {
        let value = $(`#cart_amount_input_${id}`).val()
        $(`#cart_amount_return_${id}`).text(value * return_rate)

    }
    const updateCartAmount = (e, id, return_rate, min_amount) => {

        let value = $(`#cart_amount_input_${id}`).val()
        // console.log(`#cart_amount_input_${id}`)
        if (value <= min_amount) {
            $(`#cart_amount_return_${id}`).text(min_amount * return_rate)
            $(`#cart_amount_input_${id}`).val(min_amount)
            set_order_place_button(true)
            axios.post('/api/update-cart-amount/' + id, {amount: min_amount}).then(res => {
                set_order_place_button(false)
                getCartData()
            })
        } else {
            $(`#cart_amount_return_${id}`).text(value * return_rate)
        }
        // $(`#cart_amount_return_${id}`).text(value * return_rate)


        let data = {
            amount: value
        }


        setCart_id(id)
        if (min_amount <= value) {
            set_order_place_button(true)
            axios.post('/api/update-cart-amount/' + id, data).then(res => {
                set_order_place_button(false)
                getCartData()
            })
        }

    }

    const [colors] = useState(['Red', 'Green', 'Yellow'])
    return (
        <div className="cart-data-main fixed-bottom" id={'show_data'}
             style={{display: carts.length > 0 ? 'block' : 'none'}}>
            <div className="details-data-main" id={'details_data_main'} style={{display: 'none'}}>
                <div className="details-data-inner">
                    <div className="balance-slip">
                        <p className={'bet-balance'}>Total Balance:</p>
                        <p className={'bet-balance-main'}>{account.balance} {localStorage.currency}</p>
                    </div>
                    <div className="bet-slip">
                        <p className={'bet-slip-name'}>Remaining:</p>
                        <p className={'count-data-slip'}>{account.remain} {localStorage.currency}</p>
                    </div>
                    <p className={'close-icon-bet-slip'} onClick={allClose}><i className="fas fa-times"/></p>

                </div>
                <div className="content-scroll">
                    {carts.map((cart, index) => (
                        <div className="bet-content" id={`bet_cart_items_${cart.id}`} key={index}>
                            <div
                                className={index % 2 == 1 ? 'bet-content-inner bet-content-inner-odd-bg' : 'bet-content-inner bet-content-inner-even-bg'}
                                style={{
                                    background: colors.includes(cart.number) ? cart.number : '',
                                    color: cart.number == 'Yellow' ? 'black' : ''
                                }}>
                                {cart.house_ending != null ?
                                    <div className="house-ending-in-cart">
                                        {cart.house_ending} <span>- {cart.number}</span>
                                    </div> : null
                                }

                                <div className="close-icon"
                                     onClick={(e) => removeItemFromCart(e, cart.id, cart.dom_id, cart.dom_type)}>
                                    <span className={''}><i className="fas fa-times"/></span>
                                </div>
                                <div className="investment-round">
                                    <h6>{cart.category}</h6>
                                    {cart.round != null ?
                                        <p>Round: {cart.round}</p> : null
                                    }
                                    {cart.area != null ?
                                        <p> Area: {cart.area}
                                            <span className={'ms-2'}> {cart.frsr != null ? cart.frsr : null}</span>
                                        </p> : null
                                    }

                                    <p>Time: {cart.time} </p>
                                </div>
                                <div className="investment-count-data">
                                    {cart.house_ending == null ? <h6 className={'me-2'}> {cart.number} </h6> : null}

                                </div>
                                <div className="investment-input">
                                    {/*<div className="input-group">*/}
                                    <input
                                        className={'invest-input'}
                                        type="number"
                                        min={cart.min_amount}
                                        defaultValue={cart.amount_raw}
                                        id={`cart_amount_input_${cart.id}`}
                                        onBlurCapture={(e) => updateCartAmount(e, cart.id, cart.return_rate, cart.min_amount)}
                                        onInput={(e) => handleReturnAmount(e, cart.id, cart.return_rate)}
                                        // onfocusout={(e)=>handleMinimumInput(e, cart.id, cart.return_rate)}
                                    />
                                    {/*<button onClick={(e) => updateCartAmount(e, cart.id)} className={'input-group-text btn-dark cart-amount-update-button'} dangerouslySetInnerHTML={{__html: cartUpdateLoader && cart_id == cart.id ? '<div class="spinner-border spinner-border-sm"></div>' : '<i class="fas fa-pen-square"></i>'}}/>*/}

                                    {/*</div>*/}

                                    <p>Return : <span className={'ms-1'}
                                                      id={`cart_amount_return_${cart.id}`}> {cart.return}  </span> {localStorage.currency}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="cart-data-inner" id={'cart_data_inner'}>
                <div className="cart-clickable-area" onClick={showDataMain}>
                    <h6 className={'cart-data-insert-one'} id={'slip_hide'}>Bet Slip</h6>
                    <div className="custom-bet">
                        <h6 className={'cart-data-insert-two'}>{carts.length}</h6>
                        <p className={`invest-slip-balance ${account.class}`}
                           dangerouslySetInnerHTML={{__html: cartIsLoading ? `<img src="${cartLoader}" class="cart-loader" alt="loader"/>` : account.cart_amount + ' ' + localStorage.currency}}/>
                    </div>
                </div>
                <button disabled={order_place_button} onClick={placeOrder} className={'invest-slip-btn'}
                        dangerouslySetInnerHTML={{__html: isOrderLoading ? `<img src="${cartLoader}" class="cart-loader" alt="loader"/>` : 'Place Bet'}}/>
            </div>
            <button className={'d-none'} onClick={getCartData} id={'get_cart_data'}/>
        </div>
    );
};

export default Cart;
