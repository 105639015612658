import React, {useEffect, useState} from 'react';
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import MyAccountTopBar from "./Includes/MyAccountTopBar";
import MyAccountAddFund from "./Includes/MyAccountAddFund";
import MyAccountEditForm from "./Includes/MyAccountEditForm";
import PasswordChange from "./Includes/PasswordChange";
import axios from "axios";
import {Helmet} from "react-helmet";

const MyAccount = () => {

    // useEffect(()=>{
    //     let container_width =   $('#profile_container').innerWidth()
    //     $('#profile_sidebar').css('width', container_width*25/100)
    //     $('#profile_content').css('width', container_width*75/100)
    // },[])

    const [input, setInput] = useState({})
    const [userDetails, setUserDetails] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])

    const getPaymentMethods = () => {
        axios.get('/api/get-payment-methods').then(res=>{
            setPaymentMethods(res.data)
        })
    }


    const getUserProfileInfo = () => {

        axios.get('/api/get-user-details').then(res=>{
            setInput(prevState => ({...prevState, name:res.data.data.name}))
            setInput(prevState => ({...prevState, email:res.data.data.email}))
            setInput(prevState => ({...prevState, phone:res.data.data.phone}))
            setInput(prevState => ({...prevState, username:res.data.data.username}))
            setInput(prevState => ({...prevState, country_id:res.data.data.country_id}))
            setInput(prevState => ({...prevState, address:res.data.data.address}))
            setUserDetails(res.data.data)
        })

    }

    useEffect(()=>{
        getUserProfileInfo()
        getPaymentMethods()
    }, [])




    return (
        <div>
            <Helmet>
                <title>My Account | Teer 125</title>
            </Helmet>

            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'} id={'profile_container'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class" id={'profile_sidebar'}>
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9" id={'profile_content'}>
                            <MyAccountTopBar title={'My Account'}/>
                            <MyAccountAddFund getUserProfileInfo={getUserProfileInfo} userDetails={userDetails} />
                            <MyAccountEditForm getUserProfileInfo={getUserProfileInfo} paymentMethods={paymentMethods} input={input} />
                            <PasswordChange/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
