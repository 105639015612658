import React from 'react';
import TopBar from "../../Include/TopBar";
import NavBar from "../../Include/NavBar";
import NewsTicker from "../../Include/NewsTicker";
import Footer from "../../Include/Footer";
import CopyRight from "../../Include/CopyRight";
import {Helmet} from "react-helmet";

const TermsAndConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Terms and Conditions | Teer 125</title>
            </Helmet>
            <TopBar/>
            <NavBar/>
            <NewsTicker/>

            <div className={'container'}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="about-section-main">
                            <div className="about-section-inner">
                                <h1 className={'text-center about-title'}>Terms And Conditions</h1>
                                <p className={'about-details'}>What is Lorem Ipsum?<br/>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                    with desktop publishing software like Aldus PageMaker including versions of Lorem
                                    Ipsum.</p>
                                <p className={'about-details'}>What is Lorem Ipsum?<br/>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                                    unknown printer took a galley of type and scrambled it to make a type specimen book.
                                    It has survived not only five centuries, but also the leap into electronic
                                    typesetting, remaining essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum passages, and more recently
                                    with desktop publishing software like Aldus PageMaker including versions of Lorem
                                    Ipsum.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
            <CopyRight/>
        </div>
    );
};

export default TermsAndConditions;
