import React from 'react';
import InvestmentTwentyFive from "../../Include/InvestmentTwentyFive";
import TwentyFiveResultRound from "./Include/TwentyFiveResultRound";
import UserProfileTopBar from "../UserDashboard/Include/UserProfileTopBar";
import UserProfileSideBar from "../UserDashboard/Include/UserProfileSideBar";
import {Helmet} from "react-helmet";

const TwentyFiveResult = () => {
    return (

        <div>
            <Helmet>
                <title>Result 25 | Teer 125</title>
            </Helmet>
            <UserProfileTopBar/>
            <div className="container">
                <div className={'user-profile-main'}>
                    <div className="row">
                        <div className="col-md-3 profile-sidebar-common-class">
                            <UserProfileSideBar/>
                        </div>
                        <div className="col-md-9">
                            <InvestmentTwentyFive/>
                            <TwentyFiveResultRound/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default TwentyFiveResult;
