import React, {useState} from 'react';
import Slider from "react-slick";
import image1 from "../../Assets/images/slider-image/1.jpg";
import image2 from "../../Assets/images/slider-image/2.jpg";
import image3 from "../../Assets/images/slider-image/3.jpg";
import image4 from "../../Assets/images/slider-image/4.jpg";
import image5 from "../../Assets/images/slider-image/5.jpg";
import image6 from "../../Assets/images/slider-image/6.jpg";
import image7 from "../../Assets/images/slider-image/7.jpg";

const SliderBanner = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const [sliderImage,SetSlider]=useState([
        {image:image1},
        {image:image2},
        {image:image3},
        {image:image4},
        {image:image5},
        {image:image6},
        {image:image7},
    ])
    return (
        <div className="slider-section-main">
            <Slider {...settings}>
                {sliderImage.map((item,index)=>(
                    <div className={'slider-border-radius'} key={index}>
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={item.image} className={'slider-image-height'} alt="Slider image"/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderBanner;
