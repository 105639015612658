import React, {useState} from 'react';
import {Link, useHistory, useLocation, useParams} from "react-router-dom";
import axios from "axios";
import $ from 'jquery'
import loader from "../../Assets/images/spinner/bars.svg";
import {Helmet} from "react-helmet";
import Swal from "sweetalert2";

const ForgetPassword = () => {
    const history = useHistory()
    const [input , setInput] = useState({})
    const [errors , setErrors] = useState({})
    const [loginIsLoading , setLoginIsLoading] = useState(false)
    const  useQuery = () => {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()

    const handleInput = (e) => {
        let name = e.target.name
        setInput(prev=>({...prev , [name] : e.target.value}))
    }

    const resetPassword = (e) =>{
        if (query.get("token") != undefined && query.get("email") !=undefined){
            if (input.password != undefined && input.password.length >=8){
                axios.post(`/api/reset-password?email=${ query.get("email")}&token=${query.get("token")}`, input).then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 3000,
                        toast: true
                    })
                    setTimeout(() => {
                        window.location.reload()
                    }, 1000)
                    history.push('/login')
                    $('#sign_up_model_open_button').trigger('click')
                })
            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'warning',
                    title: 'Password length minimum 8 characters',
                    showConfirmButton: false,
                    timer: 3000,
                    toast: true
                })
            }
        }else{
            Swal.fire({
                position: 'top-end',
                icon: 'warning',
                title: 'Email and Token is missing',
                showConfirmButton: false,
                timer: 3000,
                toast: true
            })
        }
    }


    return (
        <div className="container-fluid g-0 overflow-hidden">
            <Helmet>
                <title>Login | Teer 125</title>
            </Helmet>
            <div className="row">
                <div className="col-md-12">
                    <div className="login-form-section">
                        <div className="login-form-inner">
                            <form className={'login-inner-box'}>
                                <div className="mb-3">
                                    <input
                                        type="password"
                                        className="form-control login-form-control"
                                        placeholder={'Password'}
                                        name={'password'}
                                        value={input.password}
                                        onChange={handleInput}
                                    />
                                    {Object.keys(errors).length > 0 ?  Object.entries(errors).map(([key, val]) =>(
                                        <small className="text-danger validation-error-message" key={key}>{key == 'password'? val : ''}</small>
                                    )):null}
                                </div>
                                <div className="remember-and-forgot-pass">
                                    <Link to={'/login'} className={'forgot-title ms-4'}>Remember password?  Login here</Link>
                                </div>
                                <div className="submit-btn">
                                    <button onClick={resetPassword} type={'button'} className={'login-btn'} dangerouslySetInnerHTML={{__html: loginIsLoading ?  `<img src="${loader}" class="cart-loader" alt="loader"/>` :'Reset Password' }}/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
